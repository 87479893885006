export default {
  API_URL: 'https://chat.circlechess.com',
  PAYMENT_API_URL: 'https://payment.circlechess.com',
  CHAT_API_URL: 'https://chat.circlechess.com',
  ANALYZE_GAME_API_URL:
    'https://api.circlechess.com/game/start_analyzing_game',
  // CHESS_API_URL: 'https://stg.circlechess.com',
  CHESS_API_URL: 'https://api.circlechess.com',
  DEBUG_ENABLED: false,
  API_KEY: '3c311b2d-f168-47c2-9d2a-de5c2df8677a',
  GAME_SERVICE_URL: 'wss://gs.circlechess.com',
  SENTRY_DSN: 'https://b85c2838e28053a144d76198451658d6@o4506603407802368.ingest.us.sentry.io/4507893744271360',
};

