import React, {useEffect, useState} from 'react';
import {TouchableOpacity} from 'react-native';
import styles from './styles';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import Box from '@/Atoms/Box';
import CCText from '@/Atoms/CCText';
import Spacer from '@/Atoms/Spacer';
import GraphModal from './Component/GraphModal';

const Graph = ({
  data,
  data2,
  color2,
  yaxislabel,
  xaxislabel,
  color,
  label,
  stat,
  modalHeaderTitle,
  position,
  customHeaderStyle,
  yLabels,
  xLabels,
  noOfTicks,
  graphData,
  noDataText,
  tooltipText,
  level1,
  level2,
  date1,
  date2,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const {isMobileOrTab} = useMediaQuery();
  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    setOpenModal(false);
  }, []);
  const handleOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <TouchableOpacity onPress={handleOpen}>
        <Box
          style={[
            styles.streakWrapper,
            isMobileOrTab && styles.streakWrapperMobile,
          ]}>
          <Ionicons name="analytics-outline" size={20} />

          <Spacer spacing={5} horizontal />
          <CCText fontWeight="medium" style={styles.textWrapper}>
            Track Progress
          </CCText>
        </Box>
      </TouchableOpacity>

      <GraphModal
        openModal={openModal}
        handleClose={handleClose}
        data={data}
        data2={data2}
        label={label}
        color={color}
        color2={color2}
        stat={stat}
        modalHeaderTitle={modalHeaderTitle}
        yaxislabel={yaxislabel}
        xaxislabel={xaxislabel}
        position={position}
        customHeaderStyle={customHeaderStyle}
        yLabels={yLabels}
        xLabels={xLabels}
        noOfTicks={noOfTicks}
        graphData={graphData}
        noDataText={noDataText}
        tooltipText={tooltipText}
        level1={level1}
        level2={level2}
        date1={date1}
        date2={date2}
      />
    </>
  );
};

export default Graph;
