import Box from '@/Atoms/Box';
import CCSelect from '@/Atoms/CCSelect';
import CCText from '@/Atoms/CCText';
import Graph from '@/Components/Graph';
import useAssessmentFiltersApi from '@/Hooks/AssessmentApi/assessmentFilter';
import useAssessmentHistoryApi from '@/Hooks/AssessmentApi/assessmentHistory';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
import CCColors from '@/Utils/CCColors';
import {FontSizes, WINDOW_WIDTH} from '@/Utils/Dimensions';
import React, {useEffect, useState} from 'react';
import {StyleSheet, Image} from 'react-native';
import endgamewhite from '../../../../Assets/Images/Svgs/endgamewhite.svg';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
interface SkillsState {
  skill: string[];
}
const GraphComponent = () => {
  dayjs.extend(customParseFormat);
  const {isMobileOrTab} = useMediaQuery();

  const [skills, setSkills] = useState<SkillsState>({
    skill: [],
  });
  const [selectedSkillKeys, setSelectedSkillKeys] = useState<string[]>([]);
  const [selectedDurationKeys, setSelectedDurationKeys] = useState<string[]>(
    [],
  );
  const [durationArray, setDurationArray] = useState<string[]>([]);
  const [durationKeyArray, setDurationKeyArray] = useState<string[]>([]);
  const [duration, setDuration] = useState('');
  const handleDurationChange = text => {
    setDuration(text);
    const selectedKeys = durationArray
      .map((duration, index) =>
        text.includes(duration) ? durationKeyArray[index] : null,
      )
      .filter((key): key is string => key !== null);
    setSelectedDurationKeys(selectedKeys);
  };
  const [skillArray, setSkillArray] = useState<string[]>([]);
  const [skillKeyArray, setSkillKeyArray] = useState<string[]>([]);

  useEffect(() => {
    getAssessmentFilters();
    setSelectedSkillKeys([]);
    setSelectedDurationKeys([]);
  }, []);

  const handleSkillChange = selectedItems => {
    setSkills(prev => ({
      ...prev,
      skill: selectedItems,
    }));
    const selectedKeys = skillArray
      .map((skill, index) =>
        selectedItems.includes(skill) ? skillKeyArray[index] : null,
      )
      .filter((key): key is string => key !== null);

    setSelectedSkillKeys(selectedKeys);
  };

  const handleOnSuccess = (data: any) => {
    const myObject = Object.values(data?.skills);
    const myObjectKeys = Object.keys(data?.skills);

    setSkillArray(myObject as string[]);

    setDurationArray(Object.values(data?.time_range) as string[]);
    setDurationKeyArray(Object.keys(data?.time_range) as string[]);
    setSkillKeyArray(myObjectKeys as string[]);
  };

  const handleOnFailure = (data: any) => {
    console.log('error=', data);
  };
  const {
    mutate: getAssessmentFilters,
    data: filtersData,
    isLoading: filtersLoading,
    error: error,
  } = useAssessmentFiltersApi(handleOnSuccess, handleOnFailure);

  const [verticalCoords, setVerticalCoords] = useState<number[]>([]);
  const [verticalCoords2, setVerticalCoords2] = useState<number[]>([]);
  const [horizontalLabel, setHorizontalLabel] = useState([]);

  const parsedDates = horizontalLabel
    ?.map(date => dayjs(date, 'DD-MM-YY'))
    .filter(date => date?.isValid())
    .sort((a, b) => a?.valueOf() - b?.valueOf());

  const startDate = dayjs(parsedDates[0]);
  const endDate = dayjs(parsedDates[parsedDates.length - 1]);
  // Calculate the number of days between the start and end dates
  const numDays = endDate.diff(startDate, 'days');

  // Set a reasonable interval for ticks, ensuring they don't exceed endDate
  const maxTicks = selectedDurationKeys.includes('LAST_DAY') ? 1 : 5;
  const daysPerTick = Math.max(Math.floor(numDays / maxTicks), 1);

  const dates: string[] = [];
  let tickDate = startDate.clone();

  // Generate tick dates, stopping when reaching or passing endDate
  while (
    tickDate.isBefore(endDate) ||
    (tickDate.isSame(endDate, 'day') && dates.length <= maxTicks)
  ) {
    dates.push(tickDate.format('D MMM'));
    tickDate = tickDate.add(daysPerTick, 'days');
  }

  const [graphData, setGraphData] = useState(true);
  const [dataText, setDataText] = useState('');
  const [level1, setLevel1] = useState<number[]>([]);
  const [level2, setLevel2] = useState<number[]>([]);
  const [dates1, setDates1] = useState<string[]>([]);
  const [dates2, setDates2] = useState<string[]>([]);
  const handleSuccessHistory = data => {
    if (
      !data ||
      !data?.coach_assessments ||
      Object.keys(data?.coach_assessments).length === 0
    ) {
      setGraphData(false);
      setDataText('No data available');
      console.log('No data or coach assessments available.');
      return;
    }
    const formatDateToDayMonth = dateString => {
      const [day, month, year] = dateString.split('-');
      const date = new Date(`${year}-${month}-${day}`);
      return new Intl.DateTimeFormat('en', {
        month: 'short', // Use 'short' to get abbreviated month names like "Oct"
        day: 'numeric',
      }).format(date);
    };
    const coachKeys = Object.keys(data?.coach_assessments);
    const coachKeysA = data?.coach_assessments?.[coachKeys[0]]?.stats;
    const coachKeysB = data?.coach_assessments?.[coachKeys[1]]?.stats;
    const uniqueCoachKeysA = coachKeysA?.filter(
      (item, index, self) =>
        item?.date !== undefined &&
        index === self.findIndex(i => i?.date === item.date),
    );
    const uniqueCoachKeysB = coachKeysB?.filter(
      (item, index, self) =>
        item?.date !== undefined &&
        index === self.findIndex(i => i?.date === item.date),
    );
    setVerticalCoords(
      uniqueCoachKeysA?.map(i => i?.assessment_rating).reverse(),
    );

    setVerticalCoords2(
      uniqueCoachKeysB?.map(i => i?.assessment_rating).reverse(),
    );

    const combinedStats = uniqueCoachKeysA?.concat(uniqueCoachKeysB);

    // Map over combinedStats to extract dates
    const dates = combinedStats?.flatMap(item => {
      if (item && item.date) {
        return item.date; // Return the date if it exists
      } else {
        console.warn(
          'Item is missing a date or has an unexpected structure:',
          item,
        );
        return []; // Return an empty array if date is not found
      }
    });

    setLevel1(uniqueCoachKeysA?.map(i => i?.assessment_level).reverse());
    setLevel2(uniqueCoachKeysB?.map(i => i?.assessment_level).reverse());

    setDates1(
      Array.from(
        new Set(
          uniqueCoachKeysA
            ?.map(i => i?.date)
            .map(formatDateToDayMonth)
            .reverse(),
        ),
      ),
    );

    setDates2(
      Array.from(
        new Set(
          uniqueCoachKeysB
            ?.map(i => i?.date)
            .map(formatDateToDayMonth)
            .reverse(),
        ),
      ),
    );

    console.log('Dates Array:', dates);
    // const datesWithoutLast = dates.slice(0, -4);
    setHorizontalLabel(dates);
    setGraphData(true);
    setDataText('');
  };

  const handleFailureHistory = (data: any) => {
    console.log('error=', data);
  };

  const {
    mutate: getAssessmentRecord,
    data: recordData,
    isLoading: historyLoading,
  } = useAssessmentHistoryApi(handleSuccessHistory, handleFailureHistory);

  useEffect(() => {
    if (selectedSkillKeys?.length && selectedDurationKeys?.length) {
      getAssessmentRecord({
        skills: selectedSkillKeys,
        time_range: selectedDurationKeys,
      });
    } else {
      setGraphData(false);
      setDataText('Please select the skills and duration to track progress');
    }
  }, [selectedSkillKeys, duration]);
  return (
    <Graph
      level1={level1}
      level2={level2}
      date1={dates1}
      date2={dates2}
      tooltipText={'Rating'}
      noDataText={dataText}
      data={verticalCoords}
      data2={verticalCoords2}
      color2={CCColors.Red}
      graphData={graphData}
      noOfTicks={selectedDurationKeys.includes('LAST_DAY') ? 1 : 6}
      color={CCColors.Purple}
      xLabels={dates}
      customHeaderStyle={styles.absolute}
      modalHeaderTitle={
        <Box flexDirection="column" style={{width: '100%'}}>
          <Box
            flexDirection={WINDOW_WIDTH < 920 ? 'column' : 'row'}
            flexWrap="wrap"
            style={{width: '100%'}}>
            <Box
              flexDirection="row"
              justifyContent="flex-start"
              gap={10}
              style={{
                width: '50%',
                minWidth: WINDOW_WIDTH < 920 ? 300 : 0,
                flexGrow: 1,
              }}>
              <Box type="center" style={[styles.icon, {flexShrink: 0}]}>
                <Image
                  style={{width: 20, height: 20, objectFit: 'contain'}}
                  source={endgamewhite}
                />
              </Box>

              <Box
                flexDirection="column"
                style={{flexWrap: 'wrap', width: '80%', flex: 1, minWidth: 0}}>
                <CCText fontWeight="bold" fontSize={FontSizes[18]}>
                  Overall Track
                </CCText>
                <CCText
                  fontSize={FontSizes[12]}
                  style={{
                    color: CCColors.TextColor.LightGrey,
                  }}>
                  Track your skills and see real-time progress data
                </CCText>
              </Box>
            </Box>
            <Box style={{width: '50%'}}>
              <Box
                flexDirection={'row'}
                style={{
                  width: WINDOW_WIDTH < 920 ? '100%' : '',
                }}
                justifyContent={WINDOW_WIDTH < 920 ? 'null' : 'flex-end'}
                gap={5}>
                <Box>
                  <CCSelect
                    ccSelectHeight={isMobileOrTab ? 45 : 35}
                    multiSelectDropdown={true}
                    showModal={false}
                    placeholder={`Skills: ${
                      skills.skill.length > 0
                        ? `${skills.skill[0]}${
                            skills.skill.length > 1
                              ? `, +${skills.skill.length - 1}`
                              : ''
                          }`
                        : ''
                    }`}
                    items={skillArray?.map(skill => ({
                      label: skill,
                      value: skill,
                    }))}
                    limit={2}
                    multiSelect={true}
                    selectedValue={skills.skill}
                    onValueChange={handleSkillChange}
                  />
                </Box>

                <Box>
                  <CCSelect
                    ccSelectHeight={isMobileOrTab ? 45 : 35}
                    singleSelectDropdown={true}
                    showModal={false}
                    placeholder={`Duration : ${duration ? duration : ''}`}
                    items={durationArray?.map(time => ({
                      label: time,
                      value: time,
                    }))}
                    multiSelect={false}
                    selectedValue={duration}
                    onValueChange={text => handleDurationChange(text)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            gap={8}
            type="row-flex-end"
            style={[
              styles.relative,
              {
                flexWrap: 'wrap',
                marginTop: 10,
                justifyContent: 'flex-end',
                width: WINDOW_WIDTH < 920 ? '110%' : '100%',
              },
            ]}>
            {skills.skill.length !== 0 &&
              skills.skill.map((skills, index) => {
                return (
                  <Box
                    key={index}
                    alignItems="center"
                    flexDirection="row"
                    style={{
                      marginTop: 10,
                    }}
                    gap={10}>
                    <Box
                      style={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',

                        backgroundColor:
                          index === 0 ? CCColors.Purple : CCColors.Red,
                      }}></Box>
                    <CCText
                      color={CCColors.TextColor.Dark}
                      fontSize={FontSizes[16]}>
                      {skills}
                    </CCText>
                  </Box>
                );
              })}
          </Box>
        </Box>
      }
    />
  );
};
const styles = StyleSheet.create({
  icon: {
    backgroundColor: CCColors.Red,
    borderRadius: 50,
    width: 40,
    height: 40,
  },
  relative: {
    position: 'relative',
    marginTop: 10,
    zIndex: -5,
  },
  absolute: {
    position: 'absolute',
    zIndex: 100,
    marginLeft: 20,
  },
});
export default GraphComponent;
