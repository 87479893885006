import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  TextInput,
  Pressable,
} from 'react-native';
import {FontSizes, vs, WINDOW_WIDTH} from '../../Utils/Dimensions';
import CCColors from '@/Utils/CCColors';
import CCCheckbox from '../CCCheckbox';
import SlideModal from '../SlideModal';
import CCButton from '../CCButton';
import {Picker} from '@react-native-picker/picker';
import CCText from '../CCText';
import Box from '../Box';
import CCLoader from '../CCLoader';
import Spacer from '../Spacer';
import Tooltip from '../Tooltip';

interface DropdownItem {
  label: string;
  value: string;
}

interface CCSelectProps {
  label?: string;
  modalLabel?: string;
  selectedValue?: string;
  showModal?: boolean;
  selectedValues?: string[];
  onValueChange: (values: string[]) => void;
  items: DropdownItem[];
  headerComponent: JSX.Element;
  placeholder?: string;
  editable?: boolean;
  loading?: boolean;
  style?: {};
  hideBorder?: boolean;
  ccSelectHeight?: number;
  multiSelect?: boolean;
  multiSelectDropdown?: boolean;
  singleSelectDropdown?: boolean;
  position?: string;
  zIndex?: number;
  tooltip?: boolean;
  limit?: number;
}

const CCSelect: React.FC<CCSelectProps> = ({
  label,
  modalLabel,
  headerComponent,
  loading,
  selectedValue,
  selectedValues = [],
  showModal = false,
  onValueChange,
  items = [],
  placeholder = 'Select an option',
  editable = true,
  style: selectStyle,
  hideBorder = false,
  ccSelectHeight = 40,
  multiSelect = false,
  multiSelectDropdown = false,
  singleSelectDropdown = false,
  limit = 2,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSelectedValues, setTempSelectedValues] =
    useState<string[]>(selectedValues);
  const [hoverText, setHoverText] = useState(false);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleValueChange = (value: string) => {
    if (multiSelect) {
      const newValue = tempSelectedValues.includes(value)
        ? tempSelectedValues.filter(item => item !== value)
        : [...tempSelectedValues, value];
      setTempSelectedValues(newValue);
      // Immediately notify parent of changes
      onValueChange(newValue);
    } else {
      if (showModal) {
        setTempSelectedValues([value]);
      } else {
        onValueChange([value]);
        setDropdownVisible(false);
      }
    }
  };
  const handleValuesChange = (value: string) => {
    if (multiSelect) {
      if (tempSelectedValues.includes(value)) {
        // Remove the item if it's already selected
        const newValue = tempSelectedValues.filter(item => item !== value);
        setTempSelectedValues(newValue);
        onValueChange(newValue);
      } else if (tempSelectedValues.length < limit) {
        // Add the item if less than 2 items are selected
        const newValue = [...tempSelectedValues, value];
        setTempSelectedValues(newValue);
        onValueChange(newValue);
        setHoverText(false);
      } else if (tempSelectedValues.length === limit) {
        // Optionally, you could display a message here to inform the user
        console.log('only 2 items can be selected');
        setHoverText(true);
      } else {
        setHoverText(false);
      }
    }
  };

  const handleApply = () => {
    onValueChange(tempSelectedValues);
    setDropdownVisible(false);
  };
  const handlePress = (toggle: () => void) => {
    toggle(true);
    setTimeout(() => {
      toggle(false);
    }, 1000);
  };
  const clearOptions = () => {
    onValueChange([]);
    setTempSelectedValues([]);
    setDropdownVisible(false);
  };
  const filteredItems = items.filter(item =>
    item?.label?.toLowerCase().includes(searchQuery?.toLowerCase()),
  );

  const handleOutsideClick = () => {
    setDropdownVisible(false);
  };

  if (!multiSelect && !showModal && !singleSelectDropdown) {
    return (
      <View style={styles.container}>
        <Text style={styles.label}>{label}</Text>
        <Picker
          itemStyle={{
            margin: '5%',
          }}
          enabled={editable || loading}
          selectedValue={selectedValue}
          onValueChange={onValueChange}
          style={[selectStyle, styles.dropdown, {height: ccSelectHeight}]}>
          {!selectedValue && <Picker.Item label={placeholder} value="" />}
          {items.map((item, index) => (
            <Picker.Item key={index} label={item.label} value={item.value} />
          ))}
        </Picker>
      </View>
    );
  }
  if (!multiSelect && !showModal && singleSelectDropdown) {
    return (
      <View
        style={{
          backgroundColor: CCColors.White,
        }}>
        <Text style={styles.label}>{label}</Text>

        <TouchableOpacity
          style={[styles.dropdownBox, {height: ccSelectHeight}]}
          onPress={toggleDropdown}>
          <CCText
            fontSize={FontSizes[12]}
            style={{
              color: CCColors.Black,
            }}
            numberOfLines={2}>
            {selectedValues?.length > 0
              ? items
                  ?.filter(item => selectedValues.includes(item.value))
                  .map(item => item.label)
                  .join(', ')
              : placeholder}
          </CCText>
        </TouchableOpacity>

        {/* Dropdown menu */}
        {dropdownVisible && (
          <View style={styles.dropdownContentSingle}>
            <View style={{flex: 1, backgroundColor: CCColors.White}}>
              {items.map((item, index) => (
                <>
                  <TouchableOpacity
                    key={index}
                    style={styles.dropdownb}
                    onPress={() => handleValueChange(item.value)}>
                    <CCText>{item.label}</CCText>
                  </TouchableOpacity>
                </>
              ))}
            </View>
          </View>
        )}
      </View>
    );
  }
  if (multiSelect && !showModal && multiSelectDropdown) {
    return (
      <View
        style={{
          backgroundColor: CCColors.White,
          maxHeight: 200,
        }}>
        <Text style={styles.label}>{label}</Text>

        <TouchableOpacity
          style={{
            height: ccSelectHeight,
            width: WINDOW_WIDTH < 1000 ? 100 : 120,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 10,
            borderWidth: 1,
            paddingLeft: 10,
            justifyContent: 'center',
            backgroundColor: 'white',
          }}
          onPress={toggleDropdown}>
          <CCText
            fontSize={FontSizes[12]}
            style={{
              color: CCColors.Black,
            }}
            numberOfLines={2}>
            {selectedValues?.length > 0
              ? items
                  ?.filter(item => selectedValues.includes(item.value))
                  .map(item => item.label)
                  .join(', ')
              : placeholder}
          </CCText>
        </TouchableOpacity>

        {dropdownVisible && (
          <View style={styles.dropdownContentSingle}>
            <ScrollView style={{maxHeight: 200}}>
              <View>
                {items.map((item, index) => (
                  <View key={index}>
                    <TouchableOpacity
                      key={index}
                      style={styles.touchA}
                      onPress={() => handleValuesChange(item.value)}>
                      {hoverText ? (
                        <Tooltip
                          text={'You can only select 2 skills at a time'}
                          children={toggle => {
                            return (
                              <Pressable onPress={() => handlePress(toggle)}>
                                <View>
                                  {
                                    <Box flexDirection="row">
                                      {(multiSelect || showModal) && (
                                        <CCCheckbox
                                          value={tempSelectedValues.includes(
                                            item.value,
                                          )}
                                          onValueChange={() =>
                                            handleValuesChange(item.value)
                                          }
                                        />
                                      )}
                                      <CCText
                                        style={{
                                          maxWidth: 100,
                                          flexWrap: 'wrap', // Allow text wrapping
                                          maxHeight: 40,
                                        }}>
                                        {item.label}
                                      </CCText>
                                    </Box>
                                  }
                                </View>
                              </Pressable>
                            );
                          }}
                        />
                      ) : (
                        <>
                          {(multiSelect || showModal) && (
                            <CCCheckbox
                              value={tempSelectedValues.includes(item.value)}
                              onValueChange={() =>
                                handleValuesChange(item.value)
                              }
                            />
                          )}
                          <CCText
                            style={{
                              maxWidth: 100,
                              flexWrap: 'wrap', // Allow text wrapping
                              maxHeight: 40,
                            }}>
                            {item.label}
                          </CCText>
                        </>
                      )}
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </ScrollView>
          </View>
        )}
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <CCText style={styles.label}>{label}</CCText>
      <TouchableOpacity
        style={[
          selectStyle,
          styles.dropdown,
          hideBorder && styles.hideBorder,
          {height: ccSelectHeight},
        ]}
        onPress={toggleDropdown}>
        <CCText numberOfLines={2}>
          {selectedValues?.length > 0
            ? items
                ?.filter(item => selectedValues.includes(item.value))
                .map(item => item.label)
                .join(', ')
            : placeholder}
        </CCText>
      </TouchableOpacity>
      <SlideModal
        modalHeaderTitle={modalLabel}
        visible={dropdownVisible}
        type="side"
        onClose={handleOutsideClick}
        footerComponent={
          <Box type="row-center-between">
            <CCButton
              type="box"
              style={{
                backgroundColor: CCColors.White,
                borderColor: CCColors.Primary.Brown,
              }}
              titleStyle={{color: CCColors.TextColor.Primary}}
              onPress={clearOptions}>
              Clear
            </CCButton>
            <CCButton
              type="box"
              onPress={handleApply}
              titleStyle={{color: CCColors.White}}>
              Apply
            </CCButton>
          </Box>
        }>
        <View style={styles.dropdownContentContainer}>
          <TextInput
            style={styles.searchInput}
            placeholder="Search..."
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
          {headerComponent && <Box margin={10}>{headerComponent}</Box>}
          <CCLoader loading={loading} isComponentLoader />
          <ScrollView style={styles.dropdownContent}>
            {filteredItems.map((item, index) => (
              <TouchableOpacity
                key={index}
                style={styles.dropdownItem}
                onPress={() => handleValueChange(item.value)}>
                {(multiSelect || showModal) && (
                  <CCCheckbox
                    value={tempSelectedValues.includes(item.value)}
                    onValueChange={() => handleValueChange(item.value)}
                  />
                )}
                <CCText>{item.label}</CCText>
              </TouchableOpacity>
            ))}
            <Spacer spacing={60} />
          </ScrollView>
        </View>
      </SlideModal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: vs(0),
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
    fontWeight: 'bold',
  },
  dropdown: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  hideBorder: {
    backgroundColor: CCColors.White,
    borderWidth: 0,
    borderColor: CCColors.White,
  },
  dropdownContentContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 10,
    height: '100%',
  },
  searchInput: {
    padding: 10,
    borderBottomWidth: 1,
    borderColor: CCColors.Grey.Grey,
  },
  dropdownContent: {
    flex: 1,
  },
  dropdownItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  dropdownBox: {
    width: WINDOW_WIDTH < 1000 ? 100 : 120,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    paddingLeft: 10,
    justifyContent: 'center',
    backgroundColor: CCColors.White,
  },
  dropdownContentSingle: {
    paddingLeft: 2,
    justifyContent: 'center',
    backgroundColor: CCColors.White,
    width: WINDOW_WIDTH < 1000 ? 100 : 120,
    position: 'absolute',
    top: 45,
    zIndex: 1000,
    elevation: 5,
    shadowColor: CCColors.Black,
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },

  dropdownb: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 3,
    paddingLeft: 2,
    backgroundColor: CCColors.White,
  },
  touchA: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 3,
    paddingLeft: 2,
    position: 'relative',
  },
});

export default CCSelect;
