import CCColors from '@/Utils/CCColors';
import {WINDOW_WIDTH} from '@/Utils/Dimensions';
import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: CCColors?.White,
    //   padding: 20,
    justifyContent: 'flex-start',
    borderRadius: 10,
    position: 'relative',
    top: WINDOW_WIDTH < 920 ? '30%' : '25%',
    width: '90%',
    height: '80%',
  },
  mobileContainer: {
    backgroundColor: CCColors?.White,
    //   padding: 20,
    justifyContent: 'flex-start',
    borderRadius: 10,
    position: 'relative',
    top: '35%',
    width: '90%',
  },
});
export default styles;
