import Environment from '../../../environment';
import {getLocalStorage} from '@/Utils/CommonUtils';

const onFulfilled = async (config: any) => {
  // TODO: maybe don't access localstorage on every api request?
  const token = await getLocalStorage('access_token');
  if (!config.headers) {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };
  }
  if (token && !config.headers?.Authorization) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['api-key'] = `${Environment.API_KEY}`;
  return config;
};

export {onFulfilled};
