import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

interface AssessmentFiltersResponse {
  success: boolean;
  data: any[];
}

export default function useAssessmentFiltersApi(
  handleOnSuccess?: (data: AssessmentFiltersResponse) => void,
  handleOnError?: (error: any) => void,
) {
  const mutation = useMutation<AssessmentFiltersResponse, unknown, void>(
    async () => {
      const response = await ChessRestClient.get<AssessmentFiltersResponse>(
        `assessment/v1/learning/get_assessment_filters/`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Assessment Filters Data = ', data);
        // if (handleOnSuccess && data.success) {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        } else if (handleOnError) {
          handleOnError(new Error('Failed to fetch filters.'));
        }
        // }
      },
      onError: error => {
        console.error('Error fetching filters:', error);
        if (handleOnError) handleOnError(error);
      },
    },
  );

  return mutation;
}
