import {useMutation} from '@tanstack/react-query';
import ChessRestClient from '@/Api/ChessRestClient';

export default function usePuzzleHistoryApi(
  handleOnSuccess?: (data: any) => void,
  handleOnError?: (data: any) => void,
) {
  const mutation = useMutation<
    Error,
    {
      skills?: string;
      time_range?: number;
    }
  >(
    async filters => {
      const queryParams = new URLSearchParams(filters);
      const queryString = queryParams.toString();
      const response = await ChessRestClient.get(
        `assessment/v1/learning/get_assessment_history/?${queryString}`,
      );
      console.log(response.data);
      return response.data;
    },
    {
      onSuccess: data => {
        console.log('Assessment history data = ', data);
        if (handleOnSuccess && data.success) handleOnSuccess(data);
        else if (handleOnError) handleOnError(data);
      },
    },
  );

  return {
    ...mutation,
  };
}
