import Box from '../../Atoms/Box';
import CCText from '../../Atoms/CCText';
import CCButton from '../../Atoms/CCButton';
import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react';
import {
  View,
  Image,
  Button,
  TouchableHighlight,
  Picker,
  Dimensions,
  Text,
  TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
  Pressable,
} from 'react-native';
import Spacer from '@/Atoms/Spacer';
import {
  hs,
  vs,
  FontSizes,
  isPhone,
  WINDOW_WIDTH,
  WINDOW_HEIGHT,
} from '../../Utils/Dimensions';
import AntDesign from 'react-native-vector-icons/AntDesign';
import {
  styles,
  filterStyles,
  infoStyles,
  leaderBoardStyles,
  movesLogStyles,
} from './styles';
import caissaAvatar from '@/Assets/Images/Svgs/caissaAvatar.svg';
import incorrectMove from '@/Assets/Images/Svgs/IncorrectMove.svg';
import usePuzzlesApi from '@/Hooks/PuzzlesApi/puzzlesApi';
import ChessboardComponent from 'chessboard-package';
import CCSelect from '@/Atoms/CCSelect';
import usePuzzleFiltersApi from '@/Hooks/PuzzlesApi/puzzleFiltersApi';

import eco from '../../Assets/Eco/eco.json';
import ChipList from '@/Atoms/ChipList/ChipList';
import {ScreenName} from '@/navigators/StackConstants';
import useVerifyPuzzlesApi from '@/Hooks/PuzzlesApi/verifyPuzzlesApi';
import {Chess} from 'chess.js';
import {throttle} from 'lodash';
import CCColors from '@/Utils/CCColors';
import {useRoute, useNavigation} from '@react-navigation/native';
import {useFocusEffect, useIsFocused} from '@react-navigation/native';
import {PieceImages} from 'chessboard-package';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import useAnimation from '@/Hooks/useAnimation';
import Animated from 'react-native-reanimated';
import useLoggedInUserStore from '@/Store/loggedInUserstore';
import {capitalizeFirstLetter, getLocalStorage} from '@/Utils/CommonUtils';
import wrongSound from '../../Assets/Sounds/wrong.mp3';

import lockImage from '../../Assets/Images/lock.png';
import hideImage from '../../Assets/Images/hide.svg';
import * as Progress from 'react-native-progress';

import * as dayjs from 'dayjs';
import useGAPageView from '@/navigators/useGAPageView';
import useSound from 'use-sound';
import {useMediaQuery} from '@/Hooks/useMediaQuery/useMediaQuery';
var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);
import thumsup from '@/Assets/Images/Svgs/thumsup.svg';
import thumsupActive from '@/Assets/Images/Svgs/thumsupActive.svg';
import thumsdown from '@/Assets/Images/Svgs/thumsdown.svg';
import thumsdownActive from '@/Assets/Images/Svgs/thumsdownActive.svg';
import useGetLeaderBoards from '@/Hooks/getLeaderBoardApi/useGetLeaderBoardApi';
import useGetProfileApi from '@/Hooks/ProfileApi/profileApi';
import firstPrize from '../../Assets/Badges/1stPrize.png';
import secondPrize from '../../Assets/Badges/2ndPrize.png';
import thirdPrize from '../../Assets/Badges/3rdPrize.png';
import CCFlatlist from '@/Atoms/CCFlatlist';
import Hover from '@/Atoms/Hover';
// import ChessAnalysisTabs from '../ChessAnalysis/component/AnalysisRightComponent/Tabs';
import ConditionalRender from '@/Atoms/ConditionalRender';
import {ChessAnalysisTabs} from '../ChessAnalysis/component/AnalysisRightComponent/Tabs';
import StockfishComponent from '@/Components/StockfishEngine/StockfishComponent';
import {TabBar, TabView} from 'react-native-tab-view';
import {renderTabBar} from '@/Components/TabBar';
import BottomSheet from '@/Atoms/BottomSheet';
import {MOBILE_HEADER_HEIGHT} from '@/Utils/Constants';
import Tooltip from '@/Atoms/Tooltip';
import SubscriptionModal from '../Subscription/components/Modals';
import {useNonPersistedUserPlanStore} from '@/Store/userPlanStore';
import Divider from '@/Atoms/Divider/Divider';
import {Ionicons} from '@expo/vector-icons';
import {renderBoxTabBar} from '@/Atoms/CCTabs';
import InfoIcon, {CustomInfoHoverBox} from '@/Atoms/InfoIcon';
import usePuzzleFeedback from '@/Hooks/FeedbackApi/useFeedback';
import NextJSXComponent, {IncorrectJSXComponent} from './next';

const ButtonWithTooltip = ({
  element,
  requestInProgress,
  isSelected,
  opts,
  setExhausted,
  toggle,
  render,
  getFilterColor,
  tooltipContent,

  index,
}) => {
  return (
    <ConditionalRender
      condition={
        tooltipContent ||
        element.value.includes('Correct My Mistakes') ||
        element.value.includes('Find Opponent Response')
      }
      childrenA={
        <Tooltip
          text={
            element.value.includes('Moves I Miss')
              ? 'Positions where the correct move was with the selected piece(s) but you couldnot spot it '
              : element.value.includes('Correct My Mistakes')
              ? 'Find best move in positions where you made mistakes'
              : element.value.includes('Find Opponent Response')
              ? "Find opponent's best move in positions where you made mistakes "
              : tooltipContent
              ? tooltipContent
              : ''
          }
          children={toggleEvent => {
            return (
              <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                <CCButton
                  disabled={requestInProgress}
                  style={[
                    filterStyles.item,
                    isSelected(element) ? filterStyles.itemSelected : {},
                    opts?.isHorizontal ? {marginBottom: 0, marginRight: 8} : {},
                    {position: 'relative', zIndex: -10},
                  ]}
                  contentContainerStyle={filterStyles.itemContent}
                  key={element.key}
                  onPress={() => {
                    setExhausted(false);
                    toggle(element);
                  }}>
                  {render && render(element, isSelected(element))}
                  {!render && (
                    <CCText
                      style={[
                        filterStyles.itemText,
                        isSelected(element) ? {color: '#fff'} : {},
                        {color: getFilterColor(element)},
                      ]}>
                      {element.value}
                    </CCText>
                  )}
                </CCButton>
              </View>
            );
          }}
        />
      }
      childrenB={
        <CCButton
          disabled={requestInProgress}
          style={[
            filterStyles.item,
            isSelected(element) ? filterStyles.itemSelected : {},
            opts?.isHorizontal ? {marginBottom: 0, marginRight: 8} : {},
            {position: 'relative', zIndex: -10},
          ]}
          contentContainerStyle={filterStyles.itemContent}
          key={element.key}
          onPress={() => {
            setExhausted(false);
            toggle(element);
          }}>
          {render && render(element, isSelected(element))}
          {!render && (
            <CCText
              style={[
                filterStyles.itemText,
                isSelected(element) ? {color: '#fff'} : {},
                {color: getFilterColor(element)},
              ]}>
              {element.value}
            </CCText>
          )}
        </CCButton>
      }
    />
  );
};

function Timer({timeStart}) {
  const [timeTaken, setTimeTaken] = useState('');

  useEffect(() => {
    const id = setInterval(() => {
      setTimeTaken(_ => {
        return dayjs.duration(dayjs().diff(timeStart)).format('HH:mm:ss');
      });
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  return <>{timeTaken}</>;
}

function ChessBoardLoader({
  children,
  isLoading = false,
  repeatAnimation = true,
}) {
  const [boardColor, setBoardColor] = useState('');

  useEffect(() => {
    const fetchColor = async () => {
      const savedColor = await getLocalStorage('color');
      setBoardColor(savedColor);
    };

    fetchColor();
  }, []);

  const [opacityAnimStyle, startOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 0,
    end: 0.8,
    // start: startColor,
    // end: endColor,
    repeat: repeatAnimation,
    // isColor: true,
  });

  const [isLoadingDelayed, setIsLoadingDelayed] = useState(isLoading);
  // const isLoadingDelayed = isLoading;
  // console.log('loader', isLoading, isLoadingDelayed);
  useEffect(() => {
    // reset isLoadingDelayed after reloading puzzle
    if (isLoading && !isLoadingDelayed) {
      setIsLoadingDelayed(true);
    }

    if (isLoading) {
      startOpacityAnim();
    } else {
      // chessboard has loaded, wait for pieces to finish flying around
      const id = setTimeout(() => {
        setIsLoadingDelayed(false);
        // setIsLoadingDelayed = false;
      }, 600);
      return () => {
        clearInterval(id);
      };
    }
  }, [isLoading]);

  return (
    <View style={{flex: 1}}>
      {isLoadingDelayed && (
        <>
          <Animated.View
            style={[
              {
                zIndex: 2,
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: CCColors.ChessBoard.Light,
              },
              opacityAnimStyle,
            ]}></Animated.View>
          <Image
            style={{
              zIndex: 1,
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
            source={
              boardColor === 'green-white'
                ? require('@/Assets/Images/Svgs/green-chessboard.jpg')
                : boardColor === 'blue-white'
                ? require('@/Assets/Images/Svgs/blue-chessboard.jpg')
                : require('@/Assets/Images/Svgs/chessboard.svg')
            }
          />
        </>
      )}
      {children}
    </View>
  );
}

function Puzzles({dashboardData}) {
  const route = useRoute();
  useGAPageView(ScreenName.Puzzle);
  const [index, setIndex] = React.useState(0);
  const [chessboardHeight, setChessboardHeight] = useState(0);
  const scrollViewRef = useRef(null);

  const onChessboardLayout = event => {
    const {height} = event.nativeEvent.layout;
    setChessboardHeight(height);
  };
  const [disabledTabs, setDisabledTabs] = useState<boolean>(false);
  useEffect(() => {
    if (dashboardData?.user?.is_staff === false) {
      setDisabledTabs(true);
    } else {
      setDisabledTabs(false);
    }
  }, [dashboardData]);

  const [routes] = React.useState(
    [
      {key: 'SOLVE_PUZZLES', title: 'My positions', player: 'User'},

      {key: 'MASTER_GAMES', title: 'Master games', player: 'Masters'},
    ]?.filter(i => i),
  );

  const {isMobile, isTablet} = useMediaQuery();

  const isMobileOrTab = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet],
  );
  const openingMap = {};
  eco.map(e => {
    openingMap[e.eco] = e.name;
  });

  const [puzzle, setPuzzle] = useState<Object>({});
  const timeStart = dayjs();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [manualMoves, setManualMoves] = useState<
    | {
        lastFen: string;
        newFen: string;
        san: string;
      }[]
    | null
  >([]);
  const [previousFen, setPreviousFen] = useState('');
  const [startFen, setStartFen] = useState('');

  const [position, setPosition] = useState(
    'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  );
  const [selectedMobilePuzzleTab, setSelectedMobilePuzzleTab] =
    useState('Puzzle Detail');
  const [username, setUsername] = useState('');
  const [opponent, setOpponent] = useState('');
  const [platform, setPlatform] = useState('');
  const [myColor, setMyColor] = useState('');
  const [puzzleArea, setPuzzleArea] = useState('');
  const [lastMovePlayed, setLastMovePlayed] = useState('');
  const [actualMovePlayed, setActualMovePlayed] = useState('');
  const [mistake, setMistake] = useState(false);
  const [inaccuracy, setInaccuracy] = useState(false);
  const [blunder, setBlunder] = useState(false);
  const [tournament, setTournament] = useState('');
  const [Hint, setHint] = useState('');
  const [attempt, setAttempt] = useState<number>(0);
  const [goodFeedback, setGoodFeedback] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [showChange, setShowChange] = useState(false);
  const [badFeedback, setBadFeedback] = useState<number>(0);
  const [showText, setShowText] = useState(false);
  // const [orientation, setOrientation] = useState('white');

  const [stageSelected, selectStage] = useState<Array<string>>([]);
  const [selectedOpening, setSelectedOpenings] = useState<{
    black: string[];
    white: string[];
  }>({
    black: [],
    white: [],
  });
  const [selectedMaster, setSelectedMaster] = useState<{
    master: string[];
  }>({
    master: [],
  });
  const handleBlackOpeningChange = text => {
    setSelectedOpenings(prev => ({
      black: text,
      white: [], // Clear white openings when black is selected
    }));
    selectColor(['black']);
  };
  const handleMasterChange = text => {
    setSelectedMaster(prev => ({
      master: text,
    }));
    selectGm(text);
  };

  const handleWhiteOpeningChange = text => {
    setSelectedOpenings(prev => ({
      black: [], // Clear black openings when white is selected
      white: text,
    }));
    selectColor(['white']);
  };

  const [patternSelected, selectPattern] = useState<Array<string>>([]);
  const [puzzleTypeSelected, selectPuzzleType] = useState<Array<string>>([
    'Correct My Mistakes',
  ]);
  const [mistakeTypeSelected, selectMistakeType] = useState<Array<string>>([]);
  const [colorSelected, selectColor] = useState<Array<string>>([]);
  const [gm_name, selectGm] = useState<Array<string>>([]);
  const [typeSelected, selectType] = useState<Array<string>>(['Tactical']);
  const [pieceSelected, selectPiece] = useState<Array<string>>([]);
  // const [whiteOpnSelected, selectWhiteOpening] = useState([
  //   {label: 'All', value: 'All'},
  // ]);
  // const [blackOpnSelected, selectBlackOpening] = useState([
  //   {label: 'All', value: 'All'},
  // ]);
  const [playerTypeSelected, selectPlayerType] = useState('User');

  //. This enforces a exactly one selection in player type
  const selectPlayerTypeWrapper = playerType => {
    selectPlayerType([]);
    selectPlayerType(playerType);
  };

  const [result, setResult] = useState({text: '', isSuccess: false});
  const [result2, setResult2] = useState({text: '', isSuccess: false});
  const [Hint2, setHint2] = useState({text: '', isSuccess: false});

  const [colors, setColors] = useState([
    // {key: 'All', value: 'All'},
    {key: 'White', value: 'White'},
    {key: 'Black', value: 'Black'},
  ]);
  const [puzzleTypes, setPuzzleTypes] = useState([
    // {key: 'All', value: 'All'},
    {key: 'Mistake', value: 'Correct My Mistakes'},
    {key: 'Opportunity', value: 'Find Opponent Response to my Mistakes'},
  ]);
  const [mistakeTypes, setMistakeTypes] = useState([
    // {key: 'All', value: 'All'},
    {key: 'Inaccuracy', value: 'Inaccuracy'},
    {key: 'Mistake', value: 'Mistake'},
    {key: 'Blunder', value: 'Blunder'},
  ]);
  const [openingsOptions, setOpeningsOptions] = useState({
    black: [],
    white: [],
  });
  const [openingsMasters, setOpeningsMasters] = useState([]);
  const [stages, setStages] = useState([
    // {key: 'All', value: 'All'},
    {key: 'Opening', value: 'Opening'},
    {key: 'Middlegame', value: 'Middlegame'},
    {key: 'Endgame', value: 'Endgame'},
  ]);
  const [patterns, setPatterns] = useState([
    // {key: 'All', value: 'All'},
    {key: 'Mistakes', value: 'Mistakes I Make'},
    {key: 'Misses', value: 'Moves I Miss'},
  ]);
  const [types, setTypes] = useState([
    // {key: 'All', value: 'All'},
    {key: 'Tactical', value: 'Tactical'},
    {key: 'Positional', value: 'Positional'},
  ]);

  const [pieces, setPieces] = useState([
    {key: 'p', value: 'Pawn', img: PieceImages['wP'], inaccuracy: -1},
    {key: 'n', value: 'Knight', img: PieceImages['wN'], inaccuracy: -1},
    {key: 'b', value: 'Bishop', img: PieceImages['wB'], inaccuracy: -1},
    {key: 'r', value: 'Rook', img: PieceImages['wR'], inaccuracy: -1},
    {key: 'q', value: 'Queen', img: PieceImages['wQ'], inaccuracy: -1},
    {key: 'k', value: 'King', img: PieceImages['wK'], inaccuracy: -1},
  ]);
  // const [whiteOpenings, setWhiteOpenings] = useState([
  //   {key: 'All', value: 'All'},
  // ]);
  // const [blackOpenings, setBlackOpenings] = useState([
  //   {key: 'All', value: 'All'},
  // ]);
  const [playerType, setPlayerType] = useState([
    {key: 'User', value: 'User'},
    {key: 'Masters', value: 'Masters'},
  ]);
  const setStreakDetailsSummary = useLoggedInUserStore(
    state => state.setStreakDetailsSummary,
  );
  const setStreakConfig = useLoggedInUserStore(state => state.setStreakConfig);
  const [selectedValue, setSelectedValue] = useState('daily');
  const [mostSolved, setMostSolved] = useState(true);
  const [unlock, setUnlock] = useState(false);
  const [puzzlesSolved, setPuzzlesSolved] = useState(0);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [thresholdLimit, setThresholdLimit] = useState(0);
  const [visible, setVisible] = useState<boolean>(true);
  const [chessboardColorTheme, setChessboardColorTheme] = useState('');
  const [showNextModal, setShowNextModal] = useState<boolean>(true);
  const [showIncorrectHintModal, setShowIncorrectHintModal] =
    useState<boolean>(false);
  // const noOfPuzzlesSolved = useLoggedInUserStore(
  //   state => state.noOfPuzzlesSolved,
  // );
  // const setNoOfPuzzlesSolved = useLoggedInUserStore(
  //   state => state.setNoOfPuzzlesSolved,
  // );
  const [noOfPuzzlesSolved, setNoOfPuzzlesSolved] = useState<number>(5);
  const [exhausted, setExhausted] = useState<boolean>(false);
  const [attemptCounter, setAttemptCounter] = useState<number | string>(0);
  const {subscriptionModalOpen, setSubscriptionModalOpen} =
    useNonPersistedUserPlanStore();
  const [limitType, setLimitType] = useState('');
  const [usageLimit, setUsageLimit] = useState(0);
  const [planName, setPlanName] = useState('');
  const [puzzleId, setPuzzleId] = useState<number>(0);
  const [solvedCount, setSolvedCount] = useState<number>(0);
  const [puzzleAnswer, setPuzzleAnswer] = useState<string>('');

  const {width: WINDOW_WIDTH, height} = Dimensions.get('window');

  const handleOnError = (apiData: any) => {
    console.log('error=', apiData);
  };

  function getBoardOrientation(game) {
    return game.turn() == 'b' ? 'white' : 'black';
  }

  const storeMoves = (moves, curFen) => {
    const movesData = [];

    moves.forEach(move => {
      const chessNew = new Chess(curFen);
      const lastFen = chessNew?.fen();
      try {
        chessNew?.move(move);
        const newFen = chessNew?.fen();
        const san = chessNew.history({verbose: true}).slice(-1)[0].san;
        movesData.push({
          lastFen,
          newFen,
          san,
        });
      } catch (error) {
        console.log('error in chess move', error);
        return movesData;
      }
    });

    return movesData;
  };

  const handleOnSuccess = useCallback(
    (apiData: any) => {
      if (apiData.data.length === 0) {
        setExhausted(true);
        setRequestInProgress(false);
        // refresh_puzzles(true);
        return;
      }
      setExhausted(false);
      setUserId(apiData?.data[0]?.fields?.userid);
      setPuzzleAnswer(apiData?.data[0]?.fields?.best_response);
      setPuzzleId(apiData?.data[0]?.pk || 0);
      setSolvedCount(apiData?.data[0]?.fields?.solved || 0);
      setAttemptCounter(apiData?.data[0]?.fields?.no_of_attempts || 0);
      let movesString = apiData?.data[0]?.fields?.attempts || '';
      let moves = movesString.split(',');

      let movesData = storeMoves(moves, apiData?.data[0]?.fields?.fen);
      // setManualMoves(movesData);

      const game = new Chess(apiData.data[0].fields.previous_fen);
      setPreviousFen(game.fen());
      setStartFen(game.fen());
      setPuzzle({
        previousFen: game.fen(),
        currentPosition: game.fen(),
        originalPosition: game.fen(),
        uuid: apiData.data[0].fields.uuid,
        orientation: getBoardOrientation(game),
        id: apiData.data[0].pk,
        color_to_move: apiData.data[0].fields.color_to_move,
        username: apiData.data[0].fields.username,
        attempts: (str => {
          if (str === '') {
            return 0;
          } else {
            return str.split(',').length;
          }
        })(apiData.data[0].fields.attempts),
      });

      // console.log('puzzles debug 0', game.fen(), getBoardOrientation(game));

      // setPuzzleId();
      // setPuzzles(apiData.data[0].fields.fen);
      // setPrevPuzzle(apiData.data[0].fields.previous_fen);
      // setUsername();
      if (apiData.data[0].fields.platform === 'official') {
        setUsername(apiData.data[0].fields.username.split('|')[0]);
        if (apiData.data[0].fields.username.split('|')[1])
          setTournament(apiData.data[0].fields.username.split('|')[1]);
        // else setTournament('Tournament details not known');
      } else setUsername(apiData.data[0].fields.username);
      setOpponent(apiData.data[0].fields.opponent);
      setMyColor(apiData.data[0].fields.color_itg);
      setPlatform(apiData.data[0].fields.platform);
      setPuzzleArea(apiData.data[0].fields.area);
      setLastMovePlayed(apiData.data[0].fields.last_move_played);
      setActualMovePlayed(apiData.data[0].fields.actual_move_played_itg);
      setInaccuracy(apiData.data[0].fields.inaccuracy_itg === 1);
      setMistake(apiData.data[0].fields.mistake_itg === 1);
      setBlunder(apiData.data[0].fields.blunder_itg === 1);

      setTimeout(() => {
        const {last_move_played} = apiData.data[0].fields;
        makeAMove(last_move_played, true);
        setRequestInProgress(false);
      }, 1000);
      setResult({
        text: toCamelCase(getBoardOrientation(game)) + ' to play',
        isSuccess: false,
      });
      setTimeout(() => {
        setPreviousFen('');
      }, 1500);
    },
    [
      setExhausted,
      setRequestInProgress,
      setPuzzleId,
      setAttemptCounter,
      setPuzzle,
      setUsername,
      setTournament,
      setOpponent,
      setMyColor,
      setPlatform,
      setPuzzleArea,
      setLastMovePlayed,
      setActualMovePlayed,
      setInaccuracy,
      setMistake,
      setBlunder,
      setResult,
      getBoardOrientation,
      makeAMove,
      toCamelCase,
      setPreviousFen,
    ],
  );

  const makeAMove = useCallback(
    (move, isInitial = false) => {
      setPuzzle(puzzle => {
        const game = new Chess(
          result?.isSuccess
            ? manualMoves?.[manualMoves?.length - 1]?.newFen
            : puzzle.originalPosition,
        );
        const tempResult = game.move(move);
        // if (result?.isSuccess) {
        //   debugger;
        // }
        return {
          ...puzzle,
          currentPosition: result?.isSuccess
            ? manualMoves?.[manualMoves?.length - 1]?.newFen
            : game.fen(),
          originalPosition: isInitial
            ? game.fen()
            : result?.isSuccess
            ? manualMoves?.[manualMoves?.length - 1]?.lastFen
            : puzzle.originalPosition,
          lastMove: result?.isSuccess
            ? manualMoves?.[manualMoves?.length - 1]?.lastFen
            : tempResult,
        };
      });
    },
    [result, manualMoves],
  );

  const {
    mutate: getPuzzles,
    data: validatedData,
    error: validatedError,
    isLoading,
  } = usePuzzlesApi(handleOnSuccess, handleOnError);

  const {
    mutate: getLeaderBoard,
    data,
    // error,
    // isLoading,
  } = useGetLeaderBoards(handleOnSuccessLeaderBoard, handleOnErrorLeaderBoard);

  let leaderBoard = leaderBoardData;

  //let requestedUser = leaderBoardData[leaderBoardData.length - 1];
  //leaderBoard = [...leaderBoard, requestedUser];
  //console.log('2 leaderBoard=', leaderBoard);

  function handleOnSuccessLeaderBoard(data) {
    let array = data?.data;
    console.log('leaderboard data:', array);
    setLeaderBoardData(array);
    let solved_count = data?.noOfPuzzlesSolved | 0;
    let unlock_threshold_limit = data?.unlockThreshold | 0;

    setNoOfPuzzlesSolved(solved_count);
    setThresholdLimit(unlock_threshold_limit);
    if (solved_count >= unlock_threshold_limit) {
      setUnlock(true);
      setPuzzlesSolved(solved_count);
    } else {
      setUnlock(false);
    }
  }

  function handleOnErrorLeaderBoard(data) {
    console.log('error', data);
  }

  function handleProfileGetSuccess(profileData) {
    console.log('profileData=', profileData);
  }

  const [resultOpacityAnimStyle, startResultOpacityAnim] = useAnimation({
    prop: 'opacity',
    start: 1,
    end: 1,
    repeat: true,
    numberOfReps: 2,
    inputRange: [0, 0.1],
    duration: 1000,
  });

  const [incorrectSound] = useSound(wrongSound);

  const showSolutionFeedback = useCallback(
    (
      isSuccess,
      hint,
      hint_response,
      hint_response2,
      resp_move,
      description,
    ) => {
      if (isSuccess) {
        setShowNextModal(true);
        setResult({text: 'Correct!', isSuccess: true});
        setResult2({text: 'Correct!', isSuccess: true});
        setHint(` ${hint_response}`);
        setHint2(` ${hint_response2}`);
        setShowChange(false);
        setGoodFeedback(0);
        setBadFeedback(0);
      } else {
        incorrectSound();
        setResult({
          text: `"Incorrect! Opponent will respond with ${resp_move}. Try ${
            hint ? `moving the ${hint}. ${hint_response}` : 'again!'
          }`,
          isSuccess: false,
        });
        setResult2({
          text: `"Incorrect! Opponent will respond with ${resp_move}. Try ${
            hint ? `moving the ${hint}. ${hint_response2}` : 'again!'
          }`,
          isSuccess: false,
        });

        if (hint) {
          setShowIncorrectHintModal(true);
          setVisible(false);
        }
        if (!hint) {
          setVisible(true);
        }
        setHint(`${hint_response}`);
        setHint2(`${hint_response2}`);
        setShowChange(false);
        setGoodFeedback(0);
        setBadFeedback(0);
      }
      startResultOpacityAnim();
    },
    [setResult, incorrectSound, startResultOpacityAnim],
  );

  const handleOnSuccessfulVerification = (apiData: any) => {
    const {
      success,
      hint,
      attempt_id,
      resp_move,
      hint_response,
      hint_response2,
      description,
      no_of_attempts,
      points,
      net_score,
      streak_details_summary,
      streak_details,
    } = apiData;
    setLimitType(apiData?.limit_type);
    setUsageLimit(apiData?.usage_limit);
    setPlanName(apiData?.plan_name);
    if (apiData?.is_permitted === false) {
      setPuzzle(prev => {
        return {...prev, currentPosition: prev?.originalPosition};
      });
      setSubscriptionModalOpen(true);
      return;
    }
    setAttempt(apiData.attempt_id);
    getLeaderBoard({ranking_criteria: gameType, duration: selectedValue});
    scrollViewRef.current?.scrollToEnd({animated: true});
    setAttemptCounter(apiData.no_of_attempts);
    // debugger;
    // console.log('puzzles debug verify 0', apiData);

    // console.log('apiData=', apiData);

    showSolutionFeedback(
      success,
      hint,
      hint_response,
      hint_response2,
      resp_move,
      description,
    );
    if (!success) {
      // setResult(RESULT_FAILED);
      // setAttempts(no_of_attempts);
      // if (hint) {
      //   setHint(hint);
      // }

      setPuzzle(prev => {
        setPreviousFen(prev?.previousFen);
        return {...prev, currentPosition: prev?.originalPosition};
      });

      setTimeout(() => {
        setPreviousFen('');
      }, 1000);

      // setPuzzles(puzzles);
      // game.load(puzzles);
    } else {
      // setPuzzle(_ => {
      // refresh_puzzles();
      // return {};
      // });
      //
      // console.log(manualMoves, 'manualMoves');
      //       streak_details_summary
      // streak_details
      setStreakDetailsSummary(streak_details_summary);
      setStreakConfig(streak_details);
      setPuzzle(prev => {
        return {
          ...prev,
          currentPosition:
            manualMoves?.[manualMoves?.length - 1]?.newFen ||
            prev?.originalPosition,
          previousFen:
            manualMoves?.[manualMoves?.length - 1]?.lastFen ||
            prev?.previousFen,
        };
      });

      useLoggedInUserStore.setState({
        gems: points?.points,
        pointsToNextLevel: points?.points_to_next_level,
        level: points?.level,
        netScore: net_score,
      });

      // setResult(RESULT_SUCCESS);
      // setAttempts(attempts);
    }
  };

  const handleOnFailedVerification = (apiData: any) => {};

  const {
    mutate: verifyPuzzle,
    data: verificationResponse,
    error: errorResponse,
  } = useVerifyPuzzlesApi(
    handleOnSuccessfulVerification,
    handleOnFailedVerification,
  );

  const handleMove = throttle((moveType: 'next' | 'prev') => {
    // setSelectedTab('GAME REVIEW');

    switch (moveType) {
      case 'next':
        setPuzzle(prev => {
          const index = manualMoves?.findIndex(
            i => i?.newFen === prev?.currentPosition,
          );

          if (index + 1 < manualMoves?.length) {
            return {
              ...prev,
              previousFen: manualMoves[index + 1]?.lastFen,
              currentPosition: manualMoves[index + 1]?.newFen,
            };
          } else {
            return prev;
          }
        });
        break;
      case 'prev':
        setPuzzle(prev => {
          const index = manualMoves?.findIndex(
            i => i?.newFen === prev?.currentPosition,
          );

          if (index - 1 >= 0) {
            return {
              ...prev,
              previousFen: manualMoves[index - 1]?.lastFen,
              currentPosition: manualMoves[index - 1]?.newFen,
            };
          } else {
            return prev;
          }
        });
        break;
    }
  });

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === 37) {
        handleMove('prev');
      } else if (event.keyCode === 39) {
        handleMove('next');
      }
    };

    // Attach the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleMove]);

  const verifySolution = (s, d, p) => {
    if (result?.isSuccess) {
      // setPuzzle(prev => {
      //   return {
      //     ...prev,
      //     currentPosition:
      //       manualMoves?.[manualMoves?.length - 1]?.newFen ||
      //       prev?.originalPosition,
      //     previousFen:
      //       manualMoves?.[manualMoves?.length - 1]?.lastFen ||
      //       prev?.previousFen,
      //   };
      // });
      setPreviousFen('');
    } else {
      makeAMove({
        from: s,
        to: d,
        promotion: p,
      });
      setPuzzle(puzzle => {
        verifyPuzzle({puzzleId: puzzle.id, move: puzzle.lastMove.lan});
        return puzzle;
      });
    }
    return false; // chessboard lib internal
  };

  const updatePuzzleFilters = (apiData: any) => {
    const getPieceKey = piece => {
      let retVal = '';
      piece = piece.split(' ')[0];
      switch (piece?.toUpperCase()) {
        case 'KNIGHT':
          retVal = 'n';
          break;
        default:
          retVal = piece?.charAt(0)?.toLowerCase();
          break;
      }

      return retVal.toLowerCase();
    };

    const getPieceInaccuracy = piece => {
      if (!piece) return -1;

      const values = piece.split('(');
      if (values.length <= 1) {
        return -1;
      }

      let inaccuracy = values[1].trim();
      let inaccuracies = inaccuracy.split('%)');
      inaccuracy = inaccuracies[0];
      console.log(
        'inaccuracies with piece : ',
        piece,
        ' is : ',
        parseInt(inaccuracy),
      );
      return parseInt(inaccuracy);
    };

    const {
      openings,
      stage_errors,
      type_errors,
      color_errors,
      puzzleType_errors,
      pattern_errors,
      pieces_errors,
      gm_names,
    } = apiData.data;
    let t = stage_errors.map(stage => ({key: stage, value: stage}));
    setStages(t);

    t = type_errors.map(stage => ({key: stage, value: stage}));
    setTypes(t);

    t = color_errors.map(stage => ({key: stage, value: stage}));
    setColors(t);

    t = puzzleType_errors.map(stage => ({key: stage, value: stage}));
    setPuzzleTypes(t);

    t = pattern_errors.map(stage => ({key: stage, value: stage}));
    setPatterns(t);
    t = gm_names.map(stage => ({key: stage, value: stage}));
    setOpeningsMasters(t);

    console.log('Pieces = ', pieces_errors);
    t = pieces_errors.map(piece => ({
      key: getPieceKey(piece),
      value: piece,
      img: PieceImages['w' + getPieceKey(piece).toUpperCase()],
      inaccuracy: getPieceInaccuracy(piece),
    }));
    setPieces(t);
    setOpeningsOptions(openings);

    // t = openings.white.map(opening => {
    //   let ecoCode = opening.split(' ')[0].trim();
    //   if (!ecoCode || ecoCode.trim() == '' || ecoCode.trim() == '?') {
    //     return {label: 'Non-standard Openings', value: opening};
    //   }
    //   return {label: openingMap[ecoCode], value: opening};
    // });

    // setWhiteOpenings(t);

    // t = openings.black.map(opening => {
    //   let ecoCode = opening.split(' ')[0].trim();
    //   if (!ecoCode || ecoCode.trim() == '' || ecoCode.trim() == '?') {
    //     return {label: 'Non-standard Openings', value: opening};
    //   }
    //   return {label: openingMap[ecoCode], value: opening};
    // });

    // setBlackOpenings(t);
  };

  const navigation = useNavigation();

  function togglePlayerType(element) {
    selectPlayerType(prev => {
      if (prev.key == 'Masters') {
        navigation.navigate(ScreenName.Puzzles, {is_masters: undefined});
      } else {
        navigation.navigate(ScreenName.Puzzles, {is_masters: true});
      }
      return element;
    });
  }

  const handlePositionChange = tempPosition => {
    console.log('tempPosition', tempPosition);
    setManualMoves(prev => {
      const currentFEN = puzzle?.currentPosition;
      if (!currentFEN) return prev; // Or handle this case as needed

      const existingMovesForFEN = prev;
      const indexOfPrev = existingMovesForFEN?.findIndex(
        element => element?.newFen === tempPosition?.lastFen,
      );
      const updatedMovesForFEN = [...prev, tempPosition]; // Add the new move to the array
      if (result?.isSuccess) {
        setPuzzle(prev => ({
          ...prev,
          previousFen: tempPosition?.lastFen,
          currentPosition: tempPosition?.newFen,
        }));
      }
      console.log('tempPosition', tempPosition);
      return updatedMovesForFEN; // Update moves for the current FEN string
    });
  };
  // const isFocused = useIsFocused();
  //
  // useEffect(() => {
  //   setReload(reload => {
  //     if (isFocused) {
  //       console.log('zzzzz focus', isFocused);
  //       return ++reload;
  //     }
  //   });
  // }, [isFocused]);

  // useFocusEffect(
  //   React.useCallback(() => {
  //     if (!isFocused) {
  //
  //       setReload(prev => !prev);
  //     }
  //   }, [isFocused]),
  // );

  const handleNext = () => {
    refresh_puzzles();
  };
  useEffect(() => {
    // if(typeSelected.length === 1 && typeSelected[0] === 'Positional'){
    //   selectMistakeType(['Inaccuracy'])
    // }
    refresh_puzzles();
  }, [
    stageSelected,
    patternSelected,
    selectedOpening,
    selectedMaster,
    typeSelected,
    colorSelected,
    puzzleTypeSelected,
    pieceSelected,
    // whiteOpnSelected,
    // blackOpnSelected,
    // playerTypeSelected,
    index,
    mistakeTypeSelected,
  ]);

  let gameType: string = mostSolved ? 'total_puzzles' : 'first_attempt_puzzles';

  useEffect(() => {
    if (thresholdLimit - noOfPuzzlesSolved <= 0) {
      getLeaderBoard({ranking_criteria: gameType, duration: selectedValue});
    }
  }, [mostSolved, selectedValue]);

  useEffect(() => {
    const getColorTheme = async () => {
      const chessboardColorThemeTemp = await getLocalStorage('color');
      setChessboardColorTheme(chessboardColorThemeTemp);
    };

    getColorTheme();
    // return () => {
    //   second
    // }
  }, []);

  const refresh_puzzles = (isExhausted = false) => {
    setResult({text: '', isSuccess: false});
    setShowIncorrectHintModal(false);
    setShowNextModal(false);
    if (isExhausted) {
      setExhausted(false);
    }
    // Get all filters and send to the backend
    setManualMoves([]);
    var filters = {
      stageSelected,
      patternSelected,
      typeSelected,
      colorSelected,
      puzzleTypeSelected,
      pieceSelected,
      // whiteOpnSelected,
      // blackOpnSelected,
      playerTypeSelected,
      ...(playerTypeSelected !== 'User' && {gm_name}),
      mistakeTypeSelected,
    };

    if (selectedOpening?.black?.length) {
      filters['blackOpnSelected'] = selectedOpening?.black
        ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
        .join();
    }
    if (selectedOpening?.white?.length) {
      filters['whiteOpnSelected'] = selectedOpening?.white
        ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
        .join();
    }
    let puzzleFilter = {};
    if (!isExhausted) {
      if (selectedOpening?.black?.length) {
        puzzleFilter['blackOpnSelected'] = selectedOpening?.black
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }

      if (selectedOpening?.white?.length) {
        puzzleFilter['whiteOpnSelected'] = selectedOpening?.white
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (stageSelected.length) {
        puzzleFilter['stage'] = stageSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (patternSelected.length) {
        puzzleFilter['pattern'] = patternSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (typeSelected.length) {
        puzzleFilter['type'] = typeSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (Array.isArray(gm_name) && gm_name.length) {
        puzzleFilter['gm_name'] = gm_name
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }

      if (colorSelected.length) {
        puzzleFilter['color'] = colorSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (puzzleTypeSelected.length) {
        puzzleFilter['puzzleType'] = puzzleTypeSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (mistakeTypeSelected.length) {
        puzzleFilter['mistakeType'] = mistakeTypeSelected
          ?.map(i => i?.replace(/[\W\d]+/g, ''))
          .join();
      }
      if (pieceSelected.length) {
        puzzleFilter['pieces'] = pieceSelected
          ?.map(i => i?.replace(/[\W\d]+/g, ''))
          .join();
      }
    } else {
      if (stageSelected.length) {
        puzzleFilter['stage'] = '';
      }
      if (patternSelected.length) {
        puzzleFilter['pattern'] = '';
      }
      if (typeSelected.length) {
        puzzleFilter['type'] = typeSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (Array.isArray(gm_name) && gm_name.length) {
        puzzleFilter['gm_name'] = gm_name
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (colorSelected.length) {
        puzzleFilter['color'] = '';
      }
      if (puzzleTypeSelected.length) {
        puzzleFilter['puzzleType'] = puzzleTypeSelected
          ?.map(i => i?.replace(/[\W\d]+/g, '').toLowerCase())
          .join();
      }
      if (pieceSelected.length) {
        puzzleFilter['pieces'] = '';
      }
      if (mistakeTypeSelected.length) {
        puzzleFilter['mistakeType'] = '';
      }
    }

    //puzzleFilter = {...puzzleFilter, ...route.params};

    getPuzzleFilters(puzzleFilter);
    setRequestInProgress(true);

    filters = {...filters, ...route.params};

    getPuzzles(filters);
  };

  const {
    mutate: getPuzzleFilters,
    data: filtersData,
    isLoading: filtersLoading,
    error: error,
  } = usePuzzleFiltersApi(updatePuzzleFilters, () => {});

  const nextPuzzle = () => {
    setHint('');
    setResult('Pending');
    getPuzzles();
  };

  const answerIndex = manualMoves?.findIndex(move =>
    puzzleAnswer.includes(move?.san),
  );
  const planMessage = `${limitType} limit of ${usageLimit} ${
    usageLimit === 1 ? 'position' : 'positions'
  } is exhausted. Upgrade now to continue with the puzzles.`;
  function filterGroup(
    title,
    content,
    style = {},
    addInfoIcon = false,
    tooltipContent = '',
  ) {
    return (
      <View style={[filterStyles.group, style]}>
        <ConditionalRender
          condition={!addInfoIcon}
          childrenA={
            <>
              <CCText style={filterStyles.groupHeading}>{title}</CCText>
              {content}
            </>
          }
          childrenB={
            <>
              <Box
                flexDirection="row"
                alignItems="center"
                alignContent="center"
                justifyContent="flex-start"
                gap={10}>
                <Tooltip
                  position="top"
                  text={
                    <CCText color={CCColors.White}>
                      Select a Piece filter to view positions from your games
                      where either the Piece move you made was incorrect or you
                      could not spot the selected piece move
                    </CCText>
                  }
                  children={toggleEvent => {
                    return (
                      <View
                        onPointerEnter={toggleEvent}
                        onPointerLeave={toggleEvent}>
                        <AntDesign
                          name="infocirlceo"
                          size={16}
                          color={CCColors.Black}
                        />
                      </View>
                    );
                  }}
                />

                <CCText fontSize={16} style={{alignSelf: 'center'}}>
                  {title}
                </CCText>
              </Box>

              {content}
            </>
          }
        />
      </View>
    );
  }
  const {
    mutate: feedBack,
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = usePuzzleFeedback();
  async function handleSubmit(selectedFeedback: number) {
    try {
      await feedBack({
        feedback: selectedFeedback,
        puzzle_id: puzzleId,
        userid: userId,
        attempt_id: attempt,
      });
    } catch (error) {
      console.error(error);
    }
  }
  function chiplist(
    filterValue,
    setFilterValue,
    filterOptions,
    tooltipContent,
    render?,
    opts?,
  ) {
    function isSelected(element) {
      const value = element?.value?.replace(/[\W\d]+/g, '').toLowerCase();
      const filter = filterValue?.map(i =>
        i?.replace(/[\W\d]+/g, '').toLowerCase(),
      );
      return filter.indexOf(value) != -1;
    }
    function getFilterColor(filter) {
      if (isSelected(filter)) return CCColors.White;

      const filterValue = filter.value;
      if (!filterValue) return CCColors.Primary.Dark;

      const values = filterValue.split('(');
      if (values.length <= 1) {
        return CCColors.Primary.Dark;
      }

      let inaccuracy = values[1].trim();
      let inaccuracies = inaccuracy.split('%)');
      inaccuracy = inaccuracies[0];

      if (inaccuracy > 50) return CCColors.Red;
      else if (inaccuracy < 10) return CCColors.Green;
      return CCColors.Primary.Dark;
    }
    function toggle(element) {
      console.log('element is .... ', element);
      setFilterValue(filterValue => {
        const appliedToCheck = element?.value?.split(' (')?.[0];
        const selected = new Set(filterValue);
        if (selected.has(appliedToCheck)) {
          selected.delete(appliedToCheck);
        } else {
          selected.add(appliedToCheck);
          if (['Inaccuracy', 'Mistake', 'Blunder'].includes(appliedToCheck)) {
            selectType([]);
          }
          if (['Positional', 'Tactical'].includes(appliedToCheck)) {
            selectMistakeType([]);
          }
        }
        return Array.from(selected);
      });
    }

    return (
      <View
        style={[
          filterStyles.itemsContainer,
          opts?.isHorizontal ? {flexDirection: 'row', flexWrap: 'wrap'} : {},
          {overflow: 'visible'},
        ]}>
        {filterOptions.map((element, index) => {
          return (
            <>
              <ButtonWithTooltip
                tooltipContent={tooltipContent}
                element={element}
                getFilterColor={getFilterColor}
                isSelected={isSelected}
                opts={opts}
                render={render}
                requestInProgress={requestInProgress}
                setExhausted={setExhausted}
                toggle={toggle}
                key={index}
                index={index}
              />
            </>
          );
        })}
      </View>
    );
  }

  function toCamelCase(str) {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  }

  function findOpposingColor(color) {
    switch (color.toUpperCase()) {
      case 'WHITE':
        return 'Black';
      case 'BLACK':
        return 'White';
      default:
        return '';
    }
  }

  const handlePress = () => {
    if (result.text === 'Solved!' || result.text === 'Correct!') {
      setResult({text: Hint, isSuccess: true});
      setResult2({text: Hint2, isSuccess: true});
    }
  };
  const chessboardComponentJSX = (
    <Box
      margin={0}
      justifyContent="flex-start"
      overflow="scroll"
      style={[
        !isMobileOrTab
          ? styles.chessboardWebContainer
          : styles.chessboardContainer,
        // {flex: 1},
      ]}
      onLayout={onChessboardLayout}>
      <ChessBoardLoader
        isLoading={isLoading || !puzzle.id}
        repeatAnimation={isLoading}>
        <ChessboardComponent
          position={puzzle.currentPosition}
          onPieceDropExtension={verifySolution}
          lastMoveFen={previousFen}
          boardOrientation={puzzle.orientation}
          handleNewFen={handlePositionChange}
          colorTheme={chessboardColorTheme}
        />
        {/* <CCText>{manualMoves?.map(i => i?.newFen)}</CCText> */}
      </ChessBoardLoader>

      <Animated.View
        style={[
          resultOpacityAnimStyle,
          {
            backgroundColor: result.isSuccess
              ? CCColors.Primary.lightBrown
              : CCColors.Red,
          },
        ]}>
        {visible && (
          <Box
            flexDirection="row"
            alignItems="center"
            // overflow='scroll'
            flex={1}
            // onPress={() => handlePress()}
          >
            {result.text.length > 20 && (
              <Image
                resizeMethod="resize"
                source={require('@/Assets/Images/Svgs/hint.svg')}
                alt="hint"
                style={{width: 24, height: 24, marginLeft: 10}}
              />
            )}

            {exhausted && playerTypeSelected === 'User' && (
              <CCText
                color={result.isSuccess ? CCColors.Green : CCColors.White}
                textAlign="center"
                style={[styles.puzzleSolutionText, {flex: 1}]}>
                No positions available now. Either play new game to generate new
                positions or solve puzzles from GM games in master puzzle tab.
              </CCText>
            )}
            {exhausted && playerTypeSelected === 'Masters' && (
              <CCText
                color={result.isSuccess ? CCColors.Green : CCColors.White}
                textAlign="center"
                style={[styles.puzzleSolutionText, {flex: 1}]}>
                All master puzzles are solved.
              </CCText>
            )}
            {!exhausted && (
              <CCText
                textAlign={result.text.length > 20 ? 'justify' : 'center'}
                style={[
                  styles.puzzleSolutionText,
                  {color: result.isSuccess ? CCColors.Green : CCColors.White},
                  {flex: 1},
                ]}>
                {result.text}{' '}
              </CCText>
            )}
            {/* {(result.text === 'Solved!' || result.text === 'Correct!') && <CCText
              textAlign={result.text.length > 20 ? 'justify' : 'center'}
              style={[
                styles.puzzleSolutionText,
                {color: result.isSuccess ? CCColors.Green : CCColors.White},
                {flex: 1},
              ]}>
              Click to view hint.
            </CCText>} */}
            {/* {(result.text === 'Solved!') && 
            <TouchableOpacity onPress={setResult({text: Hint, isSuccess: true})}>
              <Image
              resizeMethod="resize"
              source={require('@/Assets/Images/Svgs/hint.svg')}
              alt="hint"
              style={{width: 24, height: 24, marginLeft: 10}}
              />
            </TouchableOpacity>
          } */}
          </Box>
        )}
      </Animated.View>
    </Box>
  );

  const mostSolvedHandler = () => {
    setMostSolved(true);
    // getLeaderBoard('blunder_free_games', selectedValue);
  };

  const firstAttemptHandler = () => {
    setMostSolved(false);
    // getLeaderBoard('first_attempt_puzzles', selectedValue);
  };

  let remainingPuzzles = thresholdLimit - noOfPuzzlesSolved;

  const PuzzleDetailsJSX = (
    <ScrollView
      ref={scrollViewRef}
      showsVerticalScrollIndicator={false}
      style={{
        flex: isMobileOrTab ? 1 : undefined,
        height: isMobileOrTab ? '100%' : '85vh',
        padding: !isMobileOrTab ? 10 : undefined,
        marginTop: !isMobileOrTab ? 0 : undefined,
      }}>
      {/* {platform !== 'official' && (
        <>
          <Tooltip
            position="bottom"
            text={
              <CCText color={CCColors.White}>
                Review the game from which this position originated
              </CCText>
            }
            children={toggleEvent => {
              return (
                <View onPointerEnter={toggleEvent} onPointerLeave={toggleEvent}>
                  <CCButton
                    type="outline"
                    onPress={() => {
                      navigation.navigate(ScreenName.ChessAnalysis, {
                        game_id: puzzle?.uuid,
                      });
                    }}>
                    Review Full Game
                  </CCButton>
                </View>
              );
            }}
          />
        </>
      )} */}
      {Object.hasOwn(puzzle, 'id') && (
        <View>
          <View>
            <CCText style={infoStyles.gameDetails}>
              <b>Details:</b>
            </CCText>
            <View
              style={[
                infoStyles.stat,
                {marginTop: playerTypeSelected === 'Masters' ? 5 : 10},
              ]}>
              <Icon
                name="puzzle-outline"
                size={16}
                style={[
                  infoStyles.statIcon,
                  playerTypeSelected === 'Masters' && {
                    marginBottom: 25,
                  },
                ]}
              />
              {playerTypeSelected === 'User' && (
                <Tooltip
                  position="bottom"
                  text={
                    <CCText color={CCColors.White}>
                      Review the game from which this position originated
                    </CCText>
                  }
                  children={toggleEvent => {
                    return (
                      <View
                        onPointerEnter={toggleEvent}
                        onPointerLeave={toggleEvent}>
                        <Pressable
                          onPress={() => {
                            navigation.navigate(ScreenName.ChessAnalysis, {
                              game_id: puzzle?.uuid,
                            });
                          }}>
                          <CCText
                            color={CCColors.Green}
                            style={{fontWeight: '500', fontSize: 14}}>
                            {username} ({capitalizeFirstLetter(myColor)}) vs{' '}
                            {opponent}
                          </CCText>
                        </Pressable>
                      </View>
                    );
                  }}
                />
              )}
              {playerTypeSelected === 'Masters' && (
                <Box flexDirection="column">
                  <CCText
                    color={CCColors.Green}
                    style={{
                      fontWeight: '500',
                      fontSize: 14,
                      letterSpacing: -1,
                    }}>
                    {username} ({capitalizeFirstLetter(myColor)}) vs <br />{' '}
                    {opponent}
                  </CCText>
                  <CCText style={infoStyles.gameDetails}>{tournament}</CCText>
                </Box>
              )}
            </View>
            <Box
              marginTop={playerTypeSelected === 'Masters' ? 10 : 5}
              style={{width: '80%', marginLeft: 'auto', marginRight: 'auto'}}>
              <Box flexDirection="row" style={{height: 25}}>
                <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                  Plaform:
                </CCText>
                <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                  {platform}
                </CCText>
              </Box>
              <Box flexDirection="row" style={{height: 30}}>
                <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                  Position ID:
                </CCText>
                <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                  {puzzleId}
                </CCText>
              </Box>

              {playerTypeSelected === 'User' ? (
                <Box
                  flexDirection="row"
                  style={{height: solvedCount > 0 ? 75 : 40}}>
                  <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                    Attempts:
                  </CCText>
                  <CCText
                    style={{
                      fontWeight: '500',
                      fontSize: 14,
                      flex: 1,
                      letterSpacing: -1.2,
                    }}>
                    {attemptCounter || 0}(
                    {solvedCount > 0
                      ? "Already Solved,you won't get any points"
                      : 'Yet to solve'}
                    )
                  </CCText>
                </Box>
              ) : (
                <Box
                  flexDirection="row"
                  style={{height: solvedCount > 0 ? 75 : 40}}>
                  <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                    Attempts:
                  </CCText>
                  <CCText
                    style={{
                      fontWeight: '500',
                      fontSize: 14,
                      flex: 1,
                      letterSpacing: -1,
                    }}>
                    {attemptCounter || 0}(
                    {solvedCount > 0
                      ? "Already Solved,you won't get any points"
                      : 'Yet to solve'}
                    )
                  </CCText>
                </Box>
              )}

              <Box flexDirection="row" style={{height: 25}}>
                {playerTypeSelected === 'User' && (
                  <CCText
                    style={{
                      flex: 1,
                      fontWeight: 500,
                      fontSize: 14,
                      // lineHeight: 12,
                    }}>
                    You Played:
                  </CCText>
                )}
                {playerTypeSelected === 'Masters' && (
                  <Box>
                    <CCText
                      style={{
                        fontWeight: '500',
                        fontSize: 14,
                        letterSpacing: -1,
                        lineHeight: 12,
                      }}>
                      <span
                        style={{
                          // letterSpacing: -1.5,
                          marginRight: 8,
                        }}>
                        {username}
                      </span>
                      <br />
                      Played:
                    </CCText>
                  </Box>
                )}
                {puzzleArea === 'opportunity' && (
                  <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                    {actualMovePlayed}
                  </CCText>
                )}
                {puzzleArea === 'mistake' && (
                  <CCText style={[infoStyles.gameDetails, {flex: 1}]}>
                    {lastMovePlayed}
                  </CCText>
                )}
              </Box>

              <Box flexDirection="row" style={{height: 25}}>
                {puzzleArea === 'mistake' && (
                  <CCText
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      marginTop: 8,
                      marginBottom: 20,
                    }}>
                    Find what move {opponent} responded with
                  </CCText>
                )}
              </Box>
            </Box>
            {/* {platform !== 'official' && (
              <View style={infoStyles.stat}>
                <CCText style={infoStyles.gameDetails}>
                  Plaform: {platform}
                </CCText>
              </View>
            )} */}

            {/* <CCText style={infoStyles.gameDetails}>
              Attempts:{attemptCounter || 0}(
              {solvedCount > 0
                ? "Already Solved,you won't get any points"
                : 'Yet to solve'}
              )
            </CCText> */}
            {/* {puzzleArea === 'opportunity' && (
              <CCText style={infoStyles.gameDetails}>
                {platform === 'official'
                  ? capitalizeFirstLetter(puzzle.color_to_move) + ' played'
                  : 'You Played : '}{' '}
                {actualMovePlayed} <br />
              </CCText>
            )} */}
            {/* {puzzleArea === 'mistake' && (
              <CCText style={infoStyles.gameDetails}>
                {platform === 'official'
                  ? capitalizeFirstLetter(
                      findOpposingColor(puzzle.color_to_move),
                    )
                  : 'You'}{' '}
                played : {lastMovePlayed} <br />
                Find what opponent should play as {puzzle.color_to_move}
              </CCText>
            )} */}
          </View>

          {unlock && (
            <View>
              <View
                style={[
                  leaderBoardStyles.dailyLeaderBoard,
                  playerTypeSelected === 'Masters' && {
                    marginTop: 50,
                  },
                ]}>
                <CCText
                  fontWeight="600"
                  textAlign="center"
                  fontSize={16}
                  color="#4D3F37"
                  style={{
                    lineHeight: 16,
                    letterSpacing: 0.1,
                    marginBottom: 20,
                  }}>
                  Positions Leaderboard
                </CCText>
              </View>

              <View style={leaderBoardStyles.mostFirst}>
                <CCText
                  fontWeight="500"
                  fontSize={14}
                  onPress={mostSolvedHandler}
                  style={
                    mostSolved
                      ? [
                          leaderBoardStyles.customButton,
                          leaderBoardStyles.active,
                        ]
                      : leaderBoardStyles.customButton
                  }>
                  Total Positions
                </CCText>
                <CCText
                  onPress={firstAttemptHandler}
                  fontSize={14}
                  style={
                    !mostSolved
                      ? [
                          leaderBoardStyles.customButton,
                          leaderBoardStyles.active,
                        ]
                      : leaderBoardStyles.customButton
                  }>
                  First Attempt
                </CCText>
              </View>
              <View>
                <Picker
                  selectedValue={selectedValue}
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                    padding: 1,
                    borderRadius: 2,
                    fontSize: 12,
                    width: !isMobileOrTab ? '100%' : '90%',
                    marginLeft: isMobileOrTab ? 'auto' : undefined,
                    marginRight: isMobileOrTab ? 'auto' : undefined,
                    height: 40,
                    backgroundColor: 'white',
                    border: '1px solid #22222233',
                  }}
                  onValueChange={(itemValue, itemIndex) =>
                    setSelectedValue(itemValue)
                  }>
                  <Picker.Item
                    label="Daily Leaderboard"
                    value="daily"
                    style={{
                      fontFamily: 'DM Sans',
                      fontSize: 12,
                      fontWeight: '500',
                      lineHeight: 16,
                      textAlign: 'right',
                      color: '#4D3F37',
                    }}
                  />
                  <Picker.Item
                    label="Monthly Leaderboard"
                    value="monthly"
                    style={{
                      fontFamily: 'DM Sans',
                      fontSize: 14,
                      fontWeight: '500',
                      lineHeight: 16,
                      textAlign: 'right',
                      color: '#4D3F37',
                    }}
                  />
                  <Picker.Item
                    label="All-Time Leaderboard"
                    value="all"
                    style={{
                      fontFamily: 'DM Sans',
                      fontSize: 14,
                      fontWeight: '500',
                      lineHeight: 16,
                      textAlign: 'right',
                      color: '#4D3F37',
                    }}
                  />
                </Picker>
              </View>

              <CCFlatlist
                style={{
                  width: isMobileOrTab ? '90%' : undefined,
                  marginLeft: isMobileOrTab ? 'auto' : undefined,
                  marginRight: isMobileOrTab ? 'auto' : undefined,
                }}
                data={leaderBoard}
                renderItem={({item}) => (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}>
                    {item?.rank === 1 ||
                    item?.rank === 2 ||
                    item?.rank === 3 ? (
                      <>
                        {item?.rank === 1 ? (
                          <View style={leaderBoardStyles.parentBadge}>
                            <Image
                              source={firstPrize}
                              style={leaderBoardStyles.badge}
                            />
                          </View>
                        ) : item?.rank === 2 ? (
                          <View style={leaderBoardStyles.parentBadge}>
                            <Image
                              source={secondPrize}
                              style={leaderBoardStyles.badge}
                            />
                          </View>
                        ) : (
                          <View style={leaderBoardStyles.parentBadge}>
                            <Image
                              source={thirdPrize}
                              style={leaderBoardStyles.badge}
                            />
                          </View>
                        )}
                      </>
                    ) : (
                      <View style={leaderBoardStyles.rankParent}>
                        <CCText fontSize={12} fontWeight="400" color="#4D3F37">
                          {item?.rank}
                        </CCText>
                      </View>
                    )}

                    <CCText
                      fontWeight="500"
                      fontSize={14}
                      color="#262322"
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={{
                        flex: 1,
                        marginLeft: 30,
                      }}>
                      {item?.name ? item?.name : 'N.A'}
                    </CCText>
                    <CCText fontWeight="500" fontSize={14} color="#262322">
                      {item?.puzzles_solved |
                        item?.first_attempt_puzzles_solved_count}
                    </CCText>
                  </View>
                )}
                keyExtractor={(item, index) => index.toString()}
              />
            </View>
          )}

          {!unlock && (
            <View
              style={[
                leaderBoardStyles.unlockParent,
                playerTypeSelected === 'Masters' && {
                  marginTop: 50,
                },
              ]}>
              <View style={leaderBoardStyles.unlockMiniParent}>
                <Image
                  source={lockImage}
                  style={{width: '40%', height: '40%'}}
                  resizeMode="contain"
                />
              </View>
              <CCText
                fontWeight="500"
                fontSize={16}
                textAlign="center"
                style={{
                  fontSize: 16,
                  lineHeight: 20,
                  marginTop: 10,
                }}>
                Unlock LeaderBoard!
              </CCText>
              <CCText
                textAlign="center"
                fontWeight="12"
                fontSize={14}
                style={{
                  width: '70%',
                  marginBottom: 10,
                  lineHeight: 16,
                }}>
                Solve {thresholdLimit} positions to unlock ({remainingPuzzles}{' '}
                remaining)
              </CCText>

              <View
                style={{
                  opacity: 0.9,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Progress.Bar
                  // progress={puzzlesSolved}
                  progress={noOfPuzzlesSolved / thresholdLimit}
                  width={WINDOW_WIDTH > 768 ? 190 : 100}
                  height={8}
                  color="#3DAB9E"
                  unfilledColor="gray"
                  borderWidth={0}
                />
              </View>

              <View
                style={{
                  width: 214,
                  height: 265,
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Image
                  source={hideImage}
                  style={{width: '100%', height: '100%'}}
                  resizeMode="contain"
                />
              </View>
            </View>
          )}
          {result?.isSuccess && manualMoves?.length > 0 && (
            <Box>
              <View style={movesLogStyles.movesLog}>
                <CCText style={[movesLogStyles.statText]}>
                  Further Analysis:
                </CCText>
                <Box style={movesLogStyles.movesLogBox}>
                  {manualMoves?.slice(answerIndex)?.map((i, index) => (
                    <>
                      <CCText
                        style={
                          puzzle?.currentPosition === i?.newFen
                            ? movesLogStyles.selectedMove
                            : movesLogStyles.statText
                        }
                        onPress={() => {
                          setPuzzle(prev => ({
                            ...prev,
                            previousFen: i?.lastFen,
                            currentPosition: i?.newFen,
                          }));
                          const newMoves = manualMoves.slice(
                            0,
                            answerIndex + index + 1,
                          );
                          setManualMoves(newMoves);
                        }}>
                        {i?.san}
                      </CCText>
                      {/* <CCText> , </CCText> */}
                    </>
                  ))}
                </Box>
              </View>
              <Spacer spacing={5} />
            </Box>
          )}
        </View>
      )}
    </ScrollView>
  );

  const SolvePuzzleJSX = (
    <ScrollView
      ref={scrollViewRef}
      showsVerticalScrollIndicator={false}
      style={{
        height: '100%',
      }}>
      <Box marginHorizontal={isMobileOrTab && '5%'}>
        <Box>
          <CCText
            fontSize={14}
            style={{
              fontWeight: 400,
              paddingVertical: 14,
            }}>
            These are positions from your game where you could have played
            better.
          </CCText>
        </Box>

        <View style={filterStyles.groupContainer}>
          {filterGroup(
            'Accuracy By Piece',
            chiplist(
              pieceSelected,
              selectPiece,
              pieces,
              'Select a Piece filter to view positions from your games where either the Piece move you made was incorrect or you could not spot the selected piece move',
              ({img, inaccuracy}, isSelected) => {
                let color = CCColors.Primary.Dark;
                if (inaccuracy >= 80) color = CCColors.Green;
                else if (inaccuracy < 60) color = CCColors.Red;
                return (
                  <Box>
                    <View>
                      <Image
                        resizeMode="contain"
                        style={filterStyles.pieceImage}
                        source={img}
                      />
                      {inaccuracy >= 0 && (
                        <CCText
                          style={{
                            textAlign: 'center',
                            color: isSelected ? CCColors.White : color,
                          }}>
                          {inaccuracy}%
                        </CCText>
                      )}
                    </View>
                  </Box>
                );
              },
              {isHorizontal: true},
            ),
            {flexBasis: '100%'},
            true,
            'Select a Piece filter to view positions from your games where either the Piece move you made was incorrect or you could not spot the selected piece move',
          )}
          <CCText style={{marginTop: vs(12), fontSize: vs(16)}}>
            The percentages in the following filters represent your
            inaccuracies, with higher values indicating poorer performance.
          </CCText>
          {filterGroup(
            'Black opening',
            <CCSelect
              // label={'Black opening'}
              loading={filtersLoading}
              modalLabel="Black opening"
              items={
                openingsOptions?.black?.map(item => ({
                  label: item,
                  value: item,
                })) || []
              }
              multiSelect
              selectedValues={selectedOpening?.black}
              onValueChange={text =>
                // setSelectedOpenings(prev => ({...prev, black: text}))
                handleBlackOpeningChange(text)
              }
            />,
            // chiplist()
            // chiplist(puzzleTypeSelected, selectPuzzleType, puzzleTypes),
            {flexBasis: '100%'},
          )}
          {filterGroup(
            'White opening',
            <CCSelect
              // label={'White opening'}
              loading={filtersLoading}
              modalLabel="White opening"
              items={
                openingsOptions?.white?.map(item => ({
                  label: item,
                  value: item,
                })) || []
              }
              multiSelect
              selectedValues={selectedOpening?.white}
              onValueChange={text =>
                // setSelectedOpenings(prev => ({...prev, white: text}))
                handleWhiteOpeningChange(text)
              }
            />,
            // chiplist()
            // chiplist(puzzleTypeSelected, selectPuzzleType, puzzleTypes),
            {flexBasis: '100%'},
          )}
          {filterGroup(
            'By Type',
            chiplist(puzzleTypeSelected, selectPuzzleType, puzzleTypes),
            {flexBasis: '100%'},
          )}

          {filterGroup('By Nature', chiplist(typeSelected, selectType, types))}

          {filterGroup(
            'By Pattern',
            chiplist(
              patternSelected,
              selectPattern,
              patterns,
              'Positions where you played the selected piece(s) but the move was incorrect ',
            ),
          )}
          {filterGroup(
            'By Game Stage',
            chiplist(stageSelected, selectStage, stages),
          )}

          {filterGroup(
            'By Color',
            chiplist(colorSelected, selectColor, colors),
          )}
        </View>

        <Animated.View style={resultOpacityAnimStyle}>
          {result?.isSuccess && (
            <>
              {exhausted && (
                <CCText color={CCColors.Green}>
                  Congratulations! You have solved all the poisitions for the
                  filters that you have applied. Try other positions by clicking
                  the below button
                </CCText>
              )}
              <Spacer spacing={10} />
            </>
          )}
        </Animated.View>
      </Box>
    </ScrollView>
  );

  const MasterGamesJSX = (
    <ScrollView
      ref={scrollViewRef}
      showsVerticalScrollIndicator={false}
      style={{
        flex: isMobileOrTab ? 1 : undefined,
        height: isMobileOrTab ? '100%' : undefined,
        marginHorizontal: isMobileOrTab && '5%',
      }}>
      <Box>
        <Box>
          <CCText
            fontSize={14}
            style={{
              fontWeight: 400,
              paddingVertical: 14,
            }}>
            These are positions from your grand masters' games for you to learn.
          </CCText>
        </Box>

        <View style={filterStyles.groupContainer}>
          {filterGroup(
            'Player selection',

            <CCSelect
              loading={filtersLoading}
              modalLabel="Player selection"
              items={
                openingsMasters?.map(item => ({
                  label: item.value,
                  value: item.value,
                })) || []
              }
              // multiSelect={false}
              selectedValues={selectedMaster.master}
              onValueChange={text => handleMasterChange(text)}
            />,
            {flexBasis: '100%'},
          )}

          {filterGroup('By Nature', chiplist(typeSelected, selectType, types))}

          {filterGroup(
            'By Game Stage',
            chiplist(stageSelected, selectStage, stages),
          )}

          {filterGroup(
            'By Color',
            chiplist(colorSelected, selectColor, colors),
          )}
        </View>

        <Animated.View style={resultOpacityAnimStyle}>
          {result?.isSuccess && (
            <>
              <CCText color={CCColors.Green}>
                Congratulations! You have solved all the poisitions for the
                filters that you have applied.
              </CCText>

              <Spacer spacing={10} />
            </>
          )}
        </Animated.View>
      </Box>
    </ScrollView>
  );

  // PUZZLE_DETAILS
  // SOLVE_PUZZLES
  const renderScene = (rendererSceneProps: any) => {
    const {route: tempRoute} = rendererSceneProps;
    // let returnJSX = <></>;
    switch (tempRoute.key) {
      case 'PUZZLE_DETAILS':
        return PuzzleDetailsJSX;
      case 'SOLVE_PUZZLES':
        return SolvePuzzleJSX;
      case 'MASTER_GAMES':
        return MasterGamesJSX;

      default:
        return PuzzleDetailsJSX;
    }
    // switch (tempRoute.key) {
  };
  return (
    <Box
      style={
        isMobileOrTab
          ? [styles.mobileContainer, {height: '90vh'}]
          : styles.container
      }
      // overflow="scroll"
    >
      {isMobileOrTab && (
        <>
          <Box style={styles.infoContainerMobile}>
            {chessboardComponentJSX}
            <BottomSheet
              minHeight={
                WINDOW_HEIGHT - chessboardHeight - MOBILE_HEADER_HEIGHT
              }>
              {showIncorrectHintModal && !result.isSuccess ? (
                <ScrollView
                  style={
                    isMobileOrTab
                      ? filterStyles.containerMobile
                      : filterStyles.container
                  }
                  showsVerticalScrollIndicator={false}>
                  <IncorrectJSXComponent
                    result={result}
                    result2={result2}
                    setShowIncorrectHintModal={setShowIncorrectHintModal}
                    handleSubmit={handleSubmit}
                    disabledTabs={disabledTabs}
                    exhausted={exhausted}
                    showChange={showChange}
                    setShowChange={setShowChange}
                    goodFeedback={goodFeedback}
                    badFeedback={badFeedback}
                    setGoodFeedback={setGoodFeedback}
                    setBadFeedback={setBadFeedback}
                    resultOpacityAnimStyle={resultOpacityAnimStyle}
                  />
                </ScrollView>
              ) : result.isSuccess ? (
                <ScrollView
                  style={
                    isMobileOrTab
                      ? filterStyles.containerMobile
                      : filterStyles.containerStock
                  }
                  showsVerticalScrollIndicator={false}>
                  <NextJSXComponent
                    resultOpacityAnimStyle={resultOpacityAnimStyle}
                    result={result}
                    puzzle={puzzle}
                    Hint={Hint}
                    Hint2={Hint2}
                    result2={result2}
                    manualMoves={manualMoves}
                    answerIndex={answerIndex}
                    startFen={startFen}
                    setManualMoves={setManualMoves}
                    setShowNextModal={setShowNextModal}
                    setPreviousFen={setPreviousFen}
                    setPuzzle={setPuzzle}
                    setResult={setResult}
                    handleSubmit={handleSubmit}
                    disabledTabs={disabledTabs}
                    exhausted={exhausted}
                    showChange={showChange}
                    goodFeedback={goodFeedback}
                    badFeedback={badFeedback}
                    setGoodFeedback={setGoodFeedback}
                    setBadFeedback={setBadFeedback}
                    handleNext={handleNext}
                  />
                </ScrollView>
              ) : (
                <TabView
                  lazy
                  navigationState={{index: index, routes}}
                  renderScene={renderScene}
                  renderTabBar={props => (
                    <TabBar
                      {...props}
                      activeColor="white"
                      indicatorStyle={{
                        width: '50%',
                        height: '100%',
                        backgroundColor: CCColors.Primary.Brown,
                        borderRadius: 8,
                      }}
                      inactiveColor="black"
                      style={{
                        backgroundColor: CCColors.White,
                        height: WINDOW_WIDTH < 920 ? '5vh' : 40,
                        borderRadius: 8,
                      }}
                      labelStyle={{paddingTop: 0}}
                      renderLabel={({route, focused, ...rest}) => {
                        return (
                          <CCText
                            fontWeight="semibold"
                            fontSize={14}
                            style={[
                              styles.boxTabBarLabel,
                              focused ? styles.boxIndicator : {},
                              {
                                color: focused
                                  ? CCColors?.White
                                  : CCColors?.TextColor?.Primary,
                              },
                            ]}>
                            {route.title}
                          </CCText>
                        );
                      }}
                      onTabPress={({route}) => {
                        selectPlayerType(route.player);
                      }}
                    />
                  )}
                  onIndexChange={setIndex}
                  initialLayout={{width: WINDOW_WIDTH / 3}}
                />
              )}
            </BottomSheet>
          </Box>
        </>
      )}

      {!isMobileOrTab && (
        <Box style={styles.infoWebContainer}>{PuzzleDetailsJSX}</Box>
      )}

      {!isMobileOrTab && (
        <Box style={{flex: 0.45, flexBasis: '1%'}}>
          {chessboardComponentJSX}
        </Box>
      )}
      {!isMobileOrTab && !result.isSuccess && !showIncorrectHintModal && (
        <Box style={{flex: 0.35}}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{height: '90vh'}}>
            <Box style={filterStyles.puzzleContainer}>
              <TabView
                style={{
                  width: '100%',
                  alignSelf: 'center',
                  // marginHorizontal: 'auto',
                }}
                lazy
                //   style={styles.contentContainerStyle}
                // @ts-ignore
                navigationState={{index: index, routes: routes}}
                renderScene={renderScene}
                renderTabBar={props => (
                  <TabBar
                    {...props}
                    activeColor="white"
                    indicatorStyle={{
                      width: '50%',
                      height: '100%',
                      backgroundColor: CCColors.Primary.Brown,
                      borderRadius: 8,
                    }}
                    inactiveColor="black"
                    style={{
                      backgroundColor: CCColors.White,
                      height: WINDOW_WIDTH < 920 ? '5vh' : 40,
                      borderRadius: 8,
                    }}
                    labelStyle={{paddingTop: 0}}
                    renderLabel={({route, focused, ...rest}) => {
                      return (
                        <CCText
                          fontWeight="semibold"
                          fontSize={14}
                          style={[
                            styles.boxTabBarLabel,
                            focused ? styles.boxIndicator : {},
                            {
                              color: focused
                                ? CCColors?.White
                                : CCColors?.TextColor?.Primary,
                            },
                          ]}>
                          {route.title}
                        </CCText>
                      );
                    }}
                    onTabPress={({route}) => {
                      selectPlayerType(route.player);
                    }}
                  />
                )}
                onIndexChange={setIndex}
                initialLayout={{width: WINDOW_WIDTH / 3}}
                // style={{height: '75vh'}}
              />
            </Box>
          </ScrollView>
        </Box>
      )}
      {!isMobileOrTab && result.isSuccess && (
        <Box style={{flex: 0.35, height: '100vh'}}>
          <Box style={filterStyles.puzzleNextContainer}>
            <NextJSXComponent
              result={result}
              puzzle={puzzle}
              Hint={Hint}
              Hint2={Hint2}
              result2={result2}
              manualMoves={manualMoves}
              answerIndex={answerIndex}
              startFen={startFen}
              setManualMoves={setManualMoves}
              setShowNextModal={setShowNextModal}
              setPreviousFen={setPreviousFen}
              setPuzzle={setPuzzle}
              setResult={setResult}
              handleSubmit={handleSubmit}
              disabledTabs={disabledTabs}
              exhausted={exhausted}
              showChange={showChange}
              goodFeedback={goodFeedback}
              badFeedback={badFeedback}
              setGoodFeedback={setGoodFeedback}
              setBadFeedback={setBadFeedback}
              handleNext={handleNext}
              resultOpacityAnimStyle={resultOpacityAnimStyle}
            />
          </Box>
        </Box>
      )}
      {!isMobileOrTab && showIncorrectHintModal && !result.isSuccess && (
        <Box style={{flex: 0.35, height: '92vh'}}>
          <Box style={filterStyles.puzzleIncorrectContainer}>
            <IncorrectJSXComponent
              result={result}
              result2={result2}
              setShowIncorrectHintModal={setShowIncorrectHintModal}
              handleSubmit={handleSubmit}
              disabledTabs={disabledTabs}
              exhausted={exhausted}
              showChange={showChange}
              setShowChange={setShowChange}
              goodFeedback={goodFeedback}
              badFeedback={badFeedback}
              setGoodFeedback={setGoodFeedback}
              setBadFeedback={setBadFeedback}
              resultOpacityAnimStyle={resultOpacityAnimStyle}
            />
          </Box>
        </Box>
      )}

      <SubscriptionModal
        visible={subscriptionModalOpen}
        onClose={() => setSubscriptionModalOpen(false)}
        user={dashboardData?.user}
        planMessage={planMessage}
        currentPlan={planName}
      />
    </Box>
  );
}

export default Puzzles;
